<div class="p-0">
    <chat-outer-message-details
        [message]="message"
        [messageFrom]="'Customer Messages'"
        [displayMessageFor]="'customer'"
        [name]="sender.name || 'Unknown'"
    />
    <div class="row  p-0">
        <div class="col-1 px-0 d-flex justify-content-end">
            <chat-circle [content]="'C'" />
        </div>
        <div class="col-11  p-0">
            <div class="d-flex justify-content-start">
                <div class="col-6  p-0 order-1">
                    <div
                        class="mb-1 d-flex align-items-start text-bg-other rounded"
                        aria-live="polite"
                        aria-atomic="true"
                    >
                        <chat-card>
                            <chat-display-message-content
                                [event]="event"
                                [message]="message"
                            />
                        </chat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
