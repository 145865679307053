import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IAxisSuggestedReply } from '@dxp/shared/models';
import { TruncatePipe } from '@dxp/shared/pipes';

@Component({
    selector: 'chat-text-area',
    standalone: true,
    imports: [CommonModule, FormsModule, TruncatePipe],
    templateUrl: './text-area.component.html',
    styleUrl: './text-area.component.scss',
})
export class TextAreaComponent {
    message = input.required<IAxisSuggestedReply>();

    text = computed(() => {
        return this.message().text;
    });
}
