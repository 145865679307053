import { Injectable, Signal, signal } from '@angular/core';
import { ITemplate } from '@dxp/shared/models';
import { BehaviorSubject, filter, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TemplateService {
    #showTemplates = signal<boolean>(false);

    isShowTemplates(): Signal<boolean> {
        return this.#showTemplates;
    }

    setShowTemplates(show: boolean): void {
        this.#showTemplates.set(show);
    }

    #longContent = new BehaviorSubject<{template: ITemplate, use: string}>({template: {} as ITemplate, use: ''});

    setLongContent(template: ITemplate, use: string): void {
        this.#longContent.next({template, use});
    }

    getLongContent(): Observable<{template: ITemplate, use: string}> {
        return this.#longContent.asObservable().pipe(
            filter(x => !!x.template && !!x.use),
        );
    }
}
