import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IAxisSuggestedReply as IAxisSuggestedReply } from '@dxp/shared/models';

import { SuggestedRepliesDataService } from './suggested-replies-data.service';

/**
 * SuggestedReplyService exposes the full SuggestedReply object as a signal.
 * New signals should be created to expose partial objects as needed.
 */
@Injectable({
    providedIn: 'root',
})
export class SuggestedRepliesService {
    reply: Signal<IAxisSuggestedReply>;
    replies: Signal<IAxisSuggestedReply[]>;

    constructor(
        private suggestedReplyDataService: SuggestedRepliesDataService,
    ) {
        this.replies = toSignal(this.suggestedReplyDataService.listen(), {
            initialValue: [] as IAxisSuggestedReply[],
        });

        this.reply = toSignal(
            this.suggestedReplyDataService.listenForSelectedSuggestedReplies(),
            {
                initialValue: {} as IAxisSuggestedReply,
            },
        );
    }
}
