import { CommonModule } from '@angular/common';
import { Component, OnInit, Signal, computed, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
    TelXLAttachmentComponent,
    TelXLAttachmentsService,
    TelXLButtonComponent,
} from '@dxp/elements';
import { IAxisSuggestedReply, ITemplate } from '@dxp/shared/models';
import { ChatInputService } from '@dxp/shared/services';

import { Observable, combineLatest, startWith, switchMap, tap } from 'rxjs';

import { SuggestedRepliesDataService } from '../../../../../services/suggested-replies/suggested-replies-data.service';
import { SuggestedRepliesService } from '../../../../../services/suggested-replies/suggested-replies.service';
import { TemplateService } from '../../../../../services/template/template.service';
import { SuggestedRepliesComponent } from './../../../../../components/suggested-replies/suggested-replies.component';
import { ChatMessageService } from './../../../../../services/chat-message/chat-message.service';
import { TemplateDataService } from './../../../../../services/template/template-data.service';

@Component({
    selector: 'chat-footer',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TelXLAttachmentComponent,
        TelXLButtonComponent,
        SuggestedRepliesComponent,
    ],
    providers: [ChatInputService, ChatMessageService],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit {
    attachments$: Observable<File[]>;
    attachments: File[] = [];
    attachmentsLength = signal(0);
    icon = signal('fa-light fa-file-lines');
    inputText = signal('');
    isDisabled = signal(true);
    isShowSuggestedReplies = signal(1);
    replies!: Signal<IAxisSuggestedReply[]>;
    toggleIcons = computed(() => {
        const isShowTemplates = this.templateService.isShowTemplates()();

        return isShowTemplates
            ? 'fa-solid fa-file-lines'
            : 'fa-light fa-file-lines';
    });
    footerInitialise$!: Observable<[{ template: ITemplate; use: string; } | { template: { id: string; item: string; title: string; }; use: string; }, IAxisSuggestedReply, string, File[], number]>;

    constructor(
        private suggestedRepliesService: SuggestedRepliesService,
        private attachmentsService: TelXLAttachmentsService,
        private suggestedRepliesDataService: SuggestedRepliesDataService,
        private templateDataService: TemplateDataService,
        private templateService: TemplateService,
        private userInputService: ChatInputService,
        private chatMessageService: ChatMessageService,
    ) {
        this.replies = this.suggestedRepliesService.replies;

        this.attachments$ = this.attachmentsService.attachments$.pipe(
            tap(attachments => {
                this.attachments = attachments;

                const uniqueNames = new Set<string>();

                if (attachments.length) {
                    attachments.forEach(attachment => {
                        uniqueNames.add(attachment.name);
                    });
                }
                this.userInputService.appendMessage(
                    Array.from(uniqueNames).join(', '),
                );
            }),
        );
    }

    ngOnInit(): void {
        const userInputMessages$ = this.userInputService.messages$.pipe(
            tap(x => {
                this.isDisabled.set(x.trim().length === 0);
                this.inputText.set(x);
            }),
        );

        const addTemplateToInputForEdit$ =
            this.templateDataService.addTemplateToInputForEdit$.pipe(
                switchMap(() =>
                    this.templateDataService.setSelectedTemplate$.pipe(
                        switchMap(_ =>
                            this.templateService.getLongContent().pipe(
                                tap(x => {
                                    if (x.use === 'use') {
                                        if (x.template.item)
                                            this.userInputService.appendMessage(
                                                x.template.item,
                                            );
                                    }
                                }),
                            ),
                        ),
                    ),
                ),
                startWith({
                    template: { id: '', item: '', title: '' },
                    use: '',
                }),
            );

        const listenForSuggestedReply$ = this.suggestedRepliesDataService
            .listenForSelectedSuggestedReplies()
            .pipe(
                startWith({} as IAxisSuggestedReply),
                tap(sr => {
                    if (sr.text) this.userInputService.appendMessage(sr.text);
                }),
            );

        const isShowSuggestedReplies$ =
            this.suggestedRepliesDataService.listenForIsShowSuggestedReplies();

        this.footerInitialise$ = combineLatest([
            addTemplateToInputForEdit$,
            listenForSuggestedReply$,
            userInputMessages$,
            this.attachments$,
            isShowSuggestedReplies$,
        ]);
    }

    onTextChange(chars: string) {
        this.isDisabled.set(chars.trim().length === 0);

        this.chatMessageService.onTextChange(chars);
    }

    sendMessage(messageInput: string) {
        this.chatMessageService.sendMessage(
            messageInput,
            this.attachmentsService.attachments(),
        );

        this.inputText.set('');
        this.isDisabled.set(true);
    }

    sendTyping() {
        this.chatMessageService.sendTyping();
    }

    showTemplates() {
        this.chatMessageService.showTemplates();
    }

    useSuggestedReply(sr: IAxisSuggestedReply) {
        this.chatMessageService.useSuggestedReply(sr);
    }
}
