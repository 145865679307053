import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RonaType } from '../../../../../models';

@Injectable({
    providedIn: 'root',
})
export class RonaDataService {
    #ronaConfiguration = new BehaviorSubject<Map<RonaType, number>>(
        new Map<RonaType, number>(),
    );

    setRonaConfiguration(map: Map<RonaType, number>) {
        const currentConfig = this.#ronaConfiguration.getValue();
        const newConfig = new Map([...currentConfig, ...map]);
        this.#ronaConfiguration.next(newConfig);
    }

    listen() {
        return this.#ronaConfiguration.asObservable();
    }
}
