export interface TranslateTextResp {
  translations: {
    detected_source_language: string;
    text: string;
  }[];
}

export interface TranslateToChannelRequest {
  sourceLang: string;
  fallbackTargetLang: string;
  texts: string[];
}

export interface TranslateFromChannelRequest {
  targetLang: string;
  texts: string[];
}

export interface TranslationSettings {
  featureAvailable: boolean;
  autoTranslate: boolean;
}

export interface TranslatedMessage {
  messageId: string;
  translatedText: string;
}

export const languageOptions = [
  {
    code: 'BG',
    label: 'Bulgarian',
  },
  {
    code: 'CS',
    label: 'Czech',
  },
  {
    code: 'DA',
    label: 'Danish',
  },
  {
    code: 'EN-GB',
    label: 'English (UK)',
  },
  {
    code: 'EN-US',
    label: 'English (US)',
  },
  {
    code: 'ET',
    label: 'Estonian',
  },
  {
    code: 'FR',
    label: 'French',
  },
  {
    code: 'DE',
    label: 'German',
  },
  {
    code: 'HU',
    label: 'Hungarian',
  },
  {
    code: 'ID',
    label: 'Indonesian',
  },
  {
    code: 'IT',
    label: 'Italian',
  },
  {
    code: 'LT',
    label: 'Lithuanian',
  },
  {
    code: 'NL',
    label: 'Dutch',
  },
  {
    code: 'PL',
    label: 'Polish',
  },
  {
    code: 'PT-PT',
    label: 'Portuguese',
  },
  {
    code: 'RO',
    label: 'Romanian',
  },
  {
    code: 'SK',
    label: 'Slovakian',
  },
  {
    code: 'SL',
    label: 'Slovenian',
  },
  {
    code: 'ES',
    label: 'Spanish',
  },
  {
    code: 'SV',
    label: 'Swedish',
  },
  {
    code: 'TR',
    label: 'Turkish',
  },
];