import { AgentState } from '@models/presence/user-presence';

export type ChannelType =
    | ''
    | 'webChat'
    | 'voice'
    | 'twitter'
    | 'instagram'
    | 'virtual'
    | 'whatsApp'
    | 'facebook'
    | 'sms';

export type WorkItemState =
    | 'LookUp'
    | 'Preview'
    | 'Connected'
    | 'Afterwork'
    | 'Closed';

export const MediaTypes = ['Webchat', 'Voice', 'Email', 'Messaging'] as const;
export type MediaType = (typeof MediaTypes)[number];

export type ManualAssignResult = 'Ok' | 'NotFound' | 'NotStaffed';

export enum TaskOrder {
    Voice = 1,
    Webchat = 2,
    Messaging = 3,
    Email = 4,
    Unknown = 5
}

export interface ICompleteWorkItemParams {
    workItemId: string;
    closureCode: string;
    notes: string;
}

export interface IAgentProperties {
    id: string;
    state: AgentState;
    stateSince: Date;
    breakName: string;
    capabilities: string[];
    displaySettings: {
        hideSensitiveAgentInformation: boolean;
        hideExternalContacts: boolean;
    };
    timedBreakExpiresAt: Date;
}

export interface IWorkItem {
    workItemId: string;
    conversations: IConversations;
    businessUnitId: string | null;
    customerId: string;
    workItemState: WorkItemState;
    lastStateChangeDate: string;
    createdAt: string;
    primaryConversationId: string;
    usableClosureCodes: string[];
    channelId: number;
    primaryMediaType: MediaType;
    primaryChannelType: ChannelType;
}

export interface IConversations {
    [conversationId: string]: {
        conversationId: string;
        channelType: ChannelType;
        queueName: string;
    };
}

interface ITimeStamp {
    timeStamp: string;
}

export interface IAgentClientState {
    agentProperties: IAgentProperties;
    workItems: IWorkItem[];
    timeStamp: ITimeStamp;
}

export interface IAgentStateTransitionRequested {
    toState: string;
    initiatedBy: string;
}

export interface IMessage {
    id: number;
    timeStamp: string;
    text: string;
    link: '';
}

export interface IWorkItemKpis {
    handledCount: number;
    missedCount: number;
    averageHandlingTimeInSeconds: number;
}

export interface IHandledCountKpi {
    handledCount: number;
}
