import { ChatCardComponent } from "../../card/card.component";
import { OuterMessageDetailsComponent } from "../../message/outer-message-details/outer-message-details.component";
import { CircleComponent } from "../circle/circle.component";
import { DisplayMessageComponent } from "../display/display-message-content.component";
import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { IMessage, ISender } from "@dxp/shared/models";

@Component({
    selector: 'chat-customer-message',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss'],
    standalone: true,
    imports: [
        ChatCardComponent,
        CircleComponent,
        CommonModule,
        DisplayMessageComponent,
        OuterMessageDetailsComponent,
    ],
})
export class CustomerComponent {
    @Input({ required: true }) event!: 'Message' | 'Typing';
    @Input() message!: IMessage;
    @Input() sender!: ISender;
}
