import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { DropDownComponent } from './drop-down/drop-down.component';
import { ToggleComponent } from './toggle/toggle.component';
import { TranslationService } from './services/translation/translation.service';

@Component({
    selector: 'trans-translations',
    standalone: true,
    imports: [CommonModule, ToggleComponent, DropDownComponent],
    templateUrl: './translations.component.html',
    styleUrl: './translations.component.scss',
})
export class TranslationsComponent {
    isTranslate!:boolean
    language = 'en';

    constructor(private translationService: TranslationService) {}

    setIsTranslate(isTranslate: boolean): void {
        this.translationService.setIsTranslate(isTranslate);

        this.isTranslate = isTranslate;
    }

    setSelectedLanguage(selection: string): void {
        this.translationService.setLanguageCode(selection);

        this.language = selection;
    }
}
