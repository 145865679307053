import { computed, Injectable, OnDestroy, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { AggregationsHubService } from '@signalr/aggregations/aggregations.hub.service';
import { LiveHubService } from '@signalr/live/live.hub.service';
import { Agent, IAgent, IAgentReport, IAgentState } from '@models/agent/agent';
import { CacheHandler, CacheService } from '@services/cache/cache-service';
import { LoggerService } from '@services/logger/logger.service';
import { TeamService } from '@services/team/team.service';
import { IAgentClientState, IWorkItem } from '@models/agent/agent-state';
import { AgentHubService } from '@signalr/agent/agent.hub.service';

const CACHE_TYPE = 'agent';

@Injectable({
    providedIn: 'root',
})
export class AgentService implements OnDestroy {
    private subscriptions = new Subscription();

    private _agents = signal<Agent[]>([]);
    agents = computed<IAgent[]>(() =>
        this._agents
            .asReadonly()()
            .filter(a => a.name !== ''),
    );

    private _agentWorkItems = signal<IWorkItem[]>([]);
    agentWorkItems = computed<IWorkItem[]>(() =>
        this._agentWorkItems.asReadonly()(),
    );

    constructor(
        private aggregationsHubService: AggregationsHubService,
        private liveHubService: LiveHubService,
        private agentHubService: AgentHubService,
        private teamService: TeamService,
        private cacheService: CacheService,
        private loggerService: LoggerService,
    ) {
        const reportSubscription =
            this.aggregationsHubService.agentReport$.subscribe(
                (agentReport: IAgentReport) => {
                    this.setReport(agentReport);
                },
            );

        const statesSubscription = this.liveHubService.agentStates$.subscribe(
            (agentStates: IAgentState[]) => {
                this.setState(agentStates);
            },
        );

        const stateSubscription = this.agentHubService.agentState$.subscribe(
            (agentState: IAgentClientState) => {
                this._agentWorkItems.set(agentState.workItems);
            },
        );

        this.subscriptions.add(reportSubscription);
        this.subscriptions.add(statesSubscription);
        this.subscriptions.add(stateSubscription);
    }

    set(agentIds: string[]) {
        const _agents = agentIds.map(id => new Agent(id));
        this._agents.set(_agents);

        this._agents
            .asReadonly()()
            .forEach(agent => {
                const cacheHandlers: CacheHandler<any>[] = [
                    {
                        cacheType: CACHE_TYPE,
                        cacheKey: 'state',
                        setter: data =>
                            this.setState(data as IAgentState[], false),
                    },
                    {
                        cacheType: CACHE_TYPE,
                        cacheKey: 'report',
                        setter: data =>
                            this.setReport(data as IAgentReport, false),
                    },
                ];

                cacheHandlers.forEach(handler =>
                    this.cacheService.handleCache(
                        handler.cacheType,
                        handler.cacheKey,
                        agent.id,
                        handler.setter,
                    ),
                );
            });
    }

    setReport(agentReport: IAgentReport, writeToCache = true) {
        const agentUpdate = this._agents
            .asReadonly()()
            .map(a =>
                a.id === agentReport.id
                    ? new Agent(a.id, agentReport, a.state)
                    : a,
            );

        this._agents.set(agentUpdate);
        this.teamService.updateAgent(
            agentUpdate.find(a => a.id === agentReport.id) as Agent,
        );

        if (writeToCache) {
            this.cacheService.saveToCache(
                `agent-report-${agentReport.id}`,
                agentReport,
            );
        }
    }

    setState(_agentstates: IAgentState[], writeToCache = true) {
        _agentstates
            .filter(as => as.agentName !== '')
            .forEach(as => {
                const agentUpdate = this._agents
                    .asReadonly()()
                    .map(a =>
                        a.id === as.agentId ? new Agent(a.id, a.report, as) : a,
                    );

                this._agents.set(agentUpdate);

                const agent = agentUpdate.find(a => a.id === as.agentId);
                if (agent) {
                    this.teamService.updateAgent(agent);
                } else {
                    this.loggerService.error(
                        `Agent ${as.agentName} not found as expected`,
                        as,
                    );
                }

                if (writeToCache) {
                    this.cacheService.saveToCache(`agent-state-${as.agentId}`, [
                        as,
                    ]);
                }
            });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
