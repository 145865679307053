import { Injectable, signal } from '@angular/core';
import { IAxisMessage, IMessage, IParticipantTypingEvent } from '@dxp/shared/models';
import { Observable, filter, switchMap, takeUntil, tap, timer } from 'rxjs';

import { ConversationService } from './conversation.service';
import { ChatHubIncomingService } from './hub/chat-hub-incoming.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    conversationName!: string;
    isCustomerTyping = signal<boolean>(false);

    constructor(
        private chatHubIncomingService: ChatHubIncomingService,
        private conversationService: ConversationService,
    ) {}

    participantTyping(): Observable<number> {
        return this.chatHubIncomingService.participantTyping$.pipe(
            filter(
                (participantTyping: IParticipantTypingEvent) =>
                    participantTyping.conversationId ===
                        this.conversationService.getConversationInformation()
                            ?.conversation.conversationId &&
                    participantTyping.sender.role === 'Customer',
            ),
            switchMap(() =>
                timer(0, TYPING_VISIBLE_LENGTH).pipe(
                    tap(tick => {
                        if (tick === 0) {
                            this.isCustomerTyping.set(true);
                        } else {
                            this.isCustomerTyping.set(false);
                        }
                    }),
                    takeUntil(
                        this.chatHubIncomingService.chatMessage$.pipe(
                            filter(
                                (chatMessage: IAxisMessage) =>
                                    chatMessage.conversationId ===
                                    this.conversationService.getConversationInformation()
                                        ?.conversation.conversationId,
                            ),
                        ),
                    ),
                ),
            ),
        );
    }

    typing(): IMessage {
        const message: IMessage = {
            sender: {
                role: 'Customer',
                name: this.conversationName,
            },
        } as IMessage;
        this.log('typing', message);
        return message;
    }

    private log(method: string, value: unknown) {
        console.log(`${method}:`, value);
    }
}

const TYPING_VISIBLE_LENGTH = 2000;
