import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { INavLink, navbarLinks } from '@models/menu/links';
import { SideNavItemComponent } from '@base/components/side-nav/item/side-nav-item.component';
import { ReplaceSpacesPipe } from '@pipes/replace-spaces/replace-spaces.pipe';
import { UserService } from '@services/user/user.service';
import { AuthService } from '@api/auth/auth.service';
import { environment } from '@core/environments/environment';
import { EnvironmentType } from '@models/configuration/environment';

@Component({
    selector: 'side-nav',
    standalone: true,
    templateUrl: './side-nav.component.html',
    styleUrl: './side-nav.component.scss',
    imports: [
        CommonModule,
        RouterModule,
        SideNavItemComponent,
        ReplaceSpacesPipe,
    ],
})
export class SideNavComponent {
    constructor(
        private userService: UserService,
        private authService: AuthService,
    ) {}

    presence = this.userService.presence;

    get links(): INavLink[] {
        return navbarLinks;
    }

    get allowLogout(): boolean {
        return environment.environment !== EnvironmentType.Production;
    }

    canRender(permission?: string, environments?: EnvironmentType[]): boolean {
        if (
            environments &&
            environments.length > 0 &&
            !environments.includes(environment.environment)
        ) {
            return false;
        }

        return permission ? this.userService.hasPermission(permission) : true;
    }

    logout() {
        this.authService.logout();
    }
}
