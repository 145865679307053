import { Injectable } from '@angular/core';
import { IAttachment, IDisconnectCommand } from '@dxp/shared/models';
import { UserService } from '@dxp/shared/services';

import { ChatHubWrapperService } from './hub/chat-hub-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class ChatOutgoingService {
    isConnected = false;

    constructor(
        private userService: UserService,
        private chatHubWrapperService: ChatHubWrapperService,
    ) {}

    connectChatHub(conversationId: string, asSupervisor = false) {
        if (!this.isConnected) {
            this.chatHubWrapperService.connect({
                conversationId: conversationId,
                asSupervisor: asSupervisor,
            });

            this.isConnected = true;
        }
    }

    peekOrConnectWith(conversationId: string): void {
        this.chatHubWrapperService.peekOrConnect({
            conversationId: conversationId,
        });
    }

    releaseBackToQueue(conversationId: string, queueId: string) {
        this.sendDisconnect(conversationId, queueId);
    }

    sendDisconnect(conversationId: string, queueId?: string) {
        const command: IDisconnectCommand = {
            conversationId: conversationId,
            nextNodeId: queueId,
        };

        this.chatHubWrapperService.sendDisconnect(command);
    }

    sendMessage(conversationId: string, messageText: string, file?: File) {
        let attachment: IAttachment | undefined;

        if (messageText.length > 0 || file) {
            if (file) {
                attachment = {
                    name: file?.name,
                    contentUrl: file ? URL.createObjectURL(file) : '',
                    contentType: file?.type || '',
                };
            }

            this.chatHubWrapperService.sendMessage({
                conversationId: conversationId,
                textMessage: messageText,
                isInternal: this.userService.isSupervisor(),
                attachments: attachment ? [attachment] : [],
            });

        }
    }

    sendTyping(conversationId: string) {
        this.chatHubWrapperService.sendTyping({
            conversationId: conversationId,
            isInternal: this.userService.isSupervisor(),
        });
    }
}
