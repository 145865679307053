<div class="row">
    @if (displayMessageFor === 'customer') {
        <div class="row">
            <div class="col-1 ms-1"></div>

            <div class="col-6 p-0 gap-2 d-flex justify-content-start">
                <span>{{ name }}</span>
                @if (message.createdAt) {
                    <span class="align-items-start">
                        {{ message.createdAt | date: 'HH:mm' }}
                    </span>
                }
            </div>

            <div class="col-5"></div>
        </div>
    }
    @if (displayMessageFor === 'agent') {
        <div class="row">
            <div class="col-6"></div>
            <div class="col-6 p-0 gap-2 d-flex justify-content-end">
                <span>{{ name }}</span>
                @if (message.createdAt) {
                    <span class="align-items-start">
                        {{ message.createdAt | date: 'HH:mm' }}
                    </span>
                }
            </div>
        </div>
    }
</div>
