import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TaskBeltComponent } from '@base/components/task-belt/container/task-belt.component';
import { UserService } from '@services/user/user.service';
import { MainLayoutBreakComponent } from './main-layout-break/main-layout-break.component';

@Component({
    selector: 'main-layout',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        TaskBeltComponent,
        MainLayoutBreakComponent,
    ],
    templateUrl: './main-layout.component.html',
    styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent {
    presence = this.userService.presence;

    constructor(private userService: UserService) {}
}
