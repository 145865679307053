import {
    EnvironmentType,
    IEnvironmentSettings,
} from '../models/configuration/environment';

import { LogLevel } from '../models/configuration/log';
import { createEnvironmentSettings } from './environment.factory';

export const environment: IEnvironmentSettings = createEnvironmentSettings({
    production: false,
    logLevel: LogLevel.Information,
    environment: EnvironmentType.Sand,
    authority: 'https://auth-sand.telxl.net',
    apiBaseUrl: 'https://api-sand.telxl.net',
    odataBaseUrl: 'https://odata-sand.telxl.net',
    heartbeatInterval: 30,
    pollingInterval: 60,
    cacheLifeTime: 60,
    isLoggingEnabled: false,
    isCacheEnabled: true,
    scope: 'gum bp.access openid profile email offline_access',
});
