import { Injectable } from '@angular/core';
import { environment } from '@core/environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { LoggerService } from '@services/logger/logger.service';
import { SignalRHubService } from '@signalr/signalr.hub.service';
import { IQueueInformation } from '@models/queue/queue';
import { IAgentState } from '@models/agent/agent';

@Injectable({
    providedIn: 'root',
})
export class LiveHubService extends SignalRHubService {
    private agentStatesSubject = new Subject<IAgentState[]>();
    private queueInformationSubject = new Subject<IQueueInformation>();

    agentStates$ = this.agentStatesSubject.asObservable();
    queueInformation$ = this.queueInformationSubject.asObservable();

    constructor(
        logger: LoggerService,
        oidcSecurityService: OidcSecurityService,
    ) {
        super(
            'Live',
            `${environment.liveUrl}/hub`,
            oidcSecurityService,
            logger,
        );
    }

    protected override registerHandlers() {
        this.hubConnection.on(
            'ReceiveQueueData',
            (queueInformation: IQueueInformation) => {
                this.messageReceived();

                this.logger.debug('Live Service (ReceiveQueueData) ->');
                this.logger.table(queueInformation);

                this.queueInformationSubject.next(queueInformation);
            },
        );

        this.hubConnection.on(
            'ReceiveTenantAgentStates',
            (tenantAgentStates: IAgentState[]) => {
                this.messageReceived();

                this.logger.debug('Live Service (ReceiveTenantAgentStates) ->');
                this.logger.table(tenantAgentStates);

                this.agentStatesSubject.next(tenantAgentStates);
            },
        );
    }

    protected override unregisterHandlers() {
        this.hubConnection.off('ReceiveQueueData');
        this.hubConnection.off('ReceiveTenantAgentStates');
    }
}
