import { Component, input, output } from '@angular/core';
import { TelXLButtonComponent, TelXLTransferComponent } from '@dxp/elements';
import { IAxisTransferDetails, ITransferQueue } from '@dxp/shared/models';

import { ChatOutgoingService } from '../../services/chat-outgoing.service';
import { ConversationService } from '../../services/conversation.service';

@Component({
    selector: 'chat-header-buttons',
    templateUrl: './header-buttons.component.html',
    styleUrls: ['./header-buttons.component.scss'],
    standalone: true,
    imports: [TelXLButtonComponent, TelXLTransferComponent],
})
export class HeaderButtonsComponent {
    selectedQueue = output<ITransferQueue>();
    transferDetails = input.required<IAxisTransferDetails>();

    constructor(
        private conversationService: ConversationService,
        private chatConnectionService: ChatOutgoingService,
    ) {}

    onSelectedQueue(queue: ITransferQueue) {
        this.selectedQueue.emit(queue);
    }

    sendDisconnect() {
        this.chatConnectionService.sendDisconnect(
            this.conversationService.getConversationId(),
        );
    }
}
