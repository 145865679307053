<div class="search-box">
    <div class="input-group mb-3">
        <input
            #searchInput
            type="text"
            class="form-control"
            (keyup)="onSearch($event)"
            placeholder="Search..."
        />
        <span class="input-group-text">
            <i class="fa fa-search"></i>
        </span>
    </div>
</div>
