import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { TelXLLoadingPanelComponent } from '@dxp/components';
import {
    TelXLButtonComponent,
    TelXLUserDataTextComponent,
} from '@dxp/elements';
import { StateService, UserService } from '@dxp/shared/services';

@Component({
    selector: 'app-shell-main-layout-initialisation',
    standalone: true,
    imports: [
        CommonModule,
        TelXLLoadingPanelComponent,
        TelXLButtonComponent,
        TelXLUserDataTextComponent,
    ],
    templateUrl: './main-layout-initialisation.component.html',
    styleUrl: './main-layout-initialisation.component.scss',
})
export class MainLayoutInitialisationComponent {
    loggingOut = computed<boolean>(() => this.userService.loggingOut());
    state = input.required<
        'Authenticating' | 'Loading' | 'GoLive' | 'Break' | 'Active'
    >();

    constructor(
        private stateService: StateService,
        private userService: UserService,
    ) {}

    goLive() {
        this.stateService.onActivate();
    }
}
