<div
    class="px-0 ms-2 task-item container-fluid"
    [class.selectable]="!displayTaskButtons"
    [ngClass]="{
        'task-voice': task.mediaType === 'Voice',
        'task-wrap': inWrap,
        'pulsing-border': inWrap && !isSelected,
    }"
    (click)="onClick()"
    (keydown.enter)="displayTaskButtons ? onClick() : null"
    tabindex="0"
>
    <div class="py-0 px-0 row">
        <div class="d-flex col-7">
            <div class="ms-2 me-2">
                <i [ngClass]="isSelected ? task.iconSelected : task.icon"></i>
            </div>
            <div
                class="ms-1"
                [ngClass]="{ 'task-detail-width': displayTaskButtons }"
            >
                <div class="d-flex">
                    <div class="task-name">
                        {{ task.mediaType }}
                        @if (inWrap) {
                            In Wrap
                        }
                    </div>
                </div>
                <div class="task-description pe-1">
                    {{ task.queueName }}
                </div>
                <div class="task-time mt-1">
                    {{ task.createdAt }}
                </div>
            </div>
        </div>
        <div class="col-2">
            @if (inWrap) {
                <div
                    class="font-xs mt-1 ms-1"
                    [ngClass]="
                        task.mediaType === 'Voice'
                            ? 'wrap-timer-voice'
                            : 'wrap-timer'
                    "
                >
                    {{ wrapCountdown | formatDuration }}
                </div>
            }
        </div>
        @if (inWrap && workItem().remainingWrapUpResets > 0) {
            <div class="col-3 px-2 align-self-end mb-1">
                <button
                    class="btn btn-xsm"
                    [ngClass]="
                        task.mediaType === 'Voice'
                            ? 'btn-voice-extend'
                            : 'btn-other-extend'
                    "
                    (click)="extendWrap()"
                >
                    Reset
                </button>
            </div>
        }
        <div
            *ngIf="displayTaskButtons"
            class="flex-grow-1 col-3 d-flex flex-column align-items-end"
        >
            <div class="me-2 mt-1 mb-2">
                <button
                    class="btn btn-xsm"
                    [ngClass]="
                        task.mediaType === 'Voice'
                            ? 'btn-voice-accept'
                            : 'btn-other-accept'
                    "
                    (click)="onAcceptClick()"
                >
                    Accept
                </button>
            </div>
            <div class="me-2 mt-1">
                <button
                    class="btn btn-xsm"
                    [ngClass]="
                        task.mediaType === 'Voice'
                            ? 'btn-voice-reject'
                            : 'btn-other-reject'
                    "
                    (click)="onRejectClick()"
                >
                    Reject
                </button>
            </div>
        </div>
    </div>
</div>
@if (timeout > 0 && task.workItemState === 'Preview' && displayProgress) {
    <telxl-shared-task-progress
        [totalSeconds]="timeout"
        [createdAtActual]="task.createdAtActual"
        [stop]="stopProgress"
        [isTask]="true"
    />
}
@if (inWrap) {
    <div
        class="progress-bar ms-2 me-2 bg-warning"
        role="progressbar"
        [style.width]="wrapProgress + '%'"
        [ngClass]="isSelected ? 'progress-wrap-selected' : 'progress-wrap'"
    ></div>
}
