import { CommonModule } from '@angular/common';
import { Component, Signal, computed, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { HeartbeatService } from '@dxp/shared/services';
import { map, tap } from 'rxjs';

import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
} from 'date-fns';
import { ConversationService } from '../../services/conversation.service';

@Component({
    selector: 'chat-top-right',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './top-right.component.html',
    styleUrl: './top-right.component.scss',
})
export class TopRightComponent {
    elapsedTime: Signal<string>;
    time: Signal<Date>;
    mediaType = input.required<string>();

    constructor(
        private conversationService: ConversationService,
        private heartbeatService: HeartbeatService,
    ) {
        this.time = toSignal(
            this.conversationService.conversationInformation$.pipe(
                map(ci => new Date(ci.conversation.queuedAt)),
                tap(queuedAt => {
                    console.log(queuedAt);
                }),
            ),
            { initialValue: new Date() },
        );

        this.elapsedTime = computed(() => {
            this.heartbeatService.lastHeartbeat();

            const now = Date.now();
            const elapsedMinutes = differenceInMinutes(
                now,
                this.time().getTime(),
            );
            const elapsedHours = differenceInHours(now, this.time().getTime());
            const elapsedDays = differenceInDays(now, this.time().getTime());

            return this.getElapsedTime(
                elapsedMinutes,
                elapsedHours,
                elapsedDays,
            );
        });
    }

    getElapsedTime(
        elapsedMinutes: number,
        elapsedHours: number,
        elapsedDays: number,
    ) {
        if (elapsedMinutes < 1) {
            return '<1 min ago';
        } else if (elapsedMinutes >= 1 && elapsedMinutes < 60) {
            return `${elapsedMinutes} min ago`;
        } else if (elapsedHours === 1) {
            return '1 hour ago';
        } else if (elapsedHours > 1 && elapsedHours < 24) {
            return `${elapsedHours} hours ago`;
        } else if (elapsedDays === 1) {
            return '1 day ago';
        } else {
            return `${elapsedDays} days ago`;
        }
    }

    getIconClass(mediaType: string): string {
        switch (mediaType) {
            case 'Webchat':
                return 'fa fa-message-lines';
            case 'Facebook':
                return 'fa-brands fa-facebook-f';
            case 'Instagram':
                return 'fa-brands fa-instagram';
            case 'Messaging':
                return 'fa-brands fa-whatsapp';
            default:
                return 'fa-sharp fa-regular fa-circle-question';
        }
    }
}
