import { Injectable } from '@angular/core';
import { IAxisSuggestedReply } from '@dxp/shared/models';
import { ChatInputService } from '@dxp/shared/services';

import { ConversationService } from '../conversation.service';
import { SuggestedRepliesDataService } from '../suggested-replies/suggested-replies-data.service';
import { TemplateService } from '../template/template.service';

@Injectable({
    providedIn: 'root',
})
export class ChatMessageService {
    constructor(
        private userInputService: ChatInputService,
        private conversationService: ConversationService,
        private suggestedRepliesDataService: SuggestedRepliesDataService,
        private templateService: TemplateService,
    ) {}

    onTextChange(chars: string) {
        const isInputText = chars.length === 0;

        if (isInputText) {
            this.userInputService.clearMessages();
        }

        this.userInputService.clearMessages();
        this.userInputService.appendMessage(chars);
    }

    sendMessage(messageInput: string, attachments: File[]) {
        this.userInputService.addMessage(messageInput);

        if (attachments.length > 0) {
            this.sendAttachments(attachments);
        } else {
            const currentMessage = this.userInputService.getMessage();
            this.conversationService.sendMessage(currentMessage);
        }

        this.suggestedRepliesDataService.setIsShowSuggestedReplies(0);
        this.userInputService.clearMessages();
    }

    sendTyping() {
        this.conversationService.sendTyping(
            this.conversationService.getConversationId(),
        );
    }

    showTemplates() {
        this.suggestedRepliesDataService.setIsShowSuggestedReplies(0);

        const currentState = this.templateService.isShowTemplates()();
        this.templateService.setShowTemplates(!currentState);
    }

    useSuggestedReply(sr: IAxisSuggestedReply) {
        this.suggestedRepliesDataService.setSuggestedReply(sr);
    }

    private sendAttachments(attachments: File[]) {
        attachments.forEach(attachment => {
            const currentMessage = this.userInputService.getMessage();
            this.conversationService.sendMessage(currentMessage, attachment);
        });

        this.userInputService.clearMessages();
    }
}
