<div class="row p-0">
    <div class="col-11 p-0">
        <chat-outer-message-details
            [message]="message"
            [messageFrom]="'Agent Messages'"
            [displayMessageFor]="'agent'"
            [name]="'Agent'"
        />
    </div>
</div>
<div class="row">
    <div class="col-11">
        <div class="d-flex justify-content-end">
            <div class="col-6 order-2">
                <div
                    class="mb-1 d-flex align-items-start text-bg-agent rounded"
                    aria-live="polite"
                    aria-atomic="true"
                >
                    <chat-card>
                        <chat-display-message-content
                            [event]="event"
                            [message]="message"
                        />
                    </chat-card>
                </div>
            </div>
        </div>
    </div>

    <div class="col-1 px-0 d-flex">
        <chat-circle [content]="'A'" />
    </div>
</div>
