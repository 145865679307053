import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    Signal,
    computed,
    output,
    signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TelXLPanelComponent } from '@dxp/elements';
import { AgentApiService, WorkflowApiService } from '@dxp/shared/api';
import { recalculateHeight } from '@dxp/shared/helpers';
import {
    IAxisTransferDetails,
    IConversationInformation,
    IMessage,
    ITransferAgent,
    ITransferQueue,
    IWorkItem,
    MediaType,
    WorkItemState,
} from '@dxp/shared/models';
import { SettingsService } from '@dxp/shared/services';

import { Observable, filter, map, switchMap, tap, throwError } from 'rxjs';

import { BodyComponent } from './layout/container/top/layout/content/body.component';
import { FooterComponent } from './layout/container/top/layout/footer/footer.component';
import { TopLayoutComponent } from './layout/container/top/layout/header.component';
import { ChatItemService } from './services/chat-item.service';
import { ChatOutgoingService } from './services/chat-outgoing.service';
import { ConversationService } from './services/conversation.service';

interface IChatItem {
    channelId: string;
    primaryChannelType: string;
}

@Component({
    selector: 'chat-shell',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TopLayoutComponent,
        FooterComponent,
        BodyComponent,
        TelXLPanelComponent,
    ],
    templateUrl: './chat-shell.component.html',
    styleUrl: './chat-shell.component.scss',
})
export class ChatShellComponent implements OnInit {
    transferDetails: Signal<IAxisTransferDetails>;
    chatItem!: Signal<IChatItem>;
    @Output() closeModalEmitter = new EventEmitter<boolean>();
    combinedStreams$!: Observable<any>;
    connectionStateAndWorkItemId$!: Observable<{
        workItemId: string | undefined;
        state: WorkItemState;
    }>;
    conversationInformation!: Signal<IConversationInformation | undefined>;
    height = computed<string>(() =>
        recalculateHeight('chat', this.settingsService.viewType()),
    );
    isChatItemLoading = signal(true);
    isConversationInformationLoading = signal(true);

    // Can be deleted???
    @Output() itemClicked = new EventEmitter<number>();
    mediaType = signal<MediaType>('Email');
    messageText = '';
    messages!: IMessage[];
    @Output() openModalEmitter = new EventEmitter<boolean>();
    showTemplatesEmitter = output<boolean>();
    state!: WorkItemState;
    workItem: IWorkItem | undefined;
    workItemId!: string;

    constructor(
        private conversationService: ConversationService,
        private chatItemService: ChatItemService,
        private chatConnectionService: ChatOutgoingService,
        private settingsService: SettingsService,
        // TODO: Move this api call
        private agentApiService: AgentApiService,
        private workflowApiService: WorkflowApiService,
    ) {
        const agents = toSignal(
            this.chatItemService.chatItem$.pipe(
                switchMap(chatItem =>
                    this.initializeAgents(chatItem.businessUnitId),
                ),
            ),
            { initialValue: [] as ITransferAgent[] },
        );

        const queues = toSignal(
            this.conversationService.conversationInfo$.pipe(
                switchMap(ci =>
                    this.conversationService.primaryConversationId$.pipe(
                        switchMap(conversationId => {
                            if (ci.queueId) {
                                return this.initializeQueues(
                                    conversationId,
                                    ci.queueId,
                                );
                            }

                            return throwError(
                                () => new Error('Queue ID not found'),
                            );
                        }),
                    ),
                ),
            ),
            { initialValue: [] as ITransferQueue[] },
        );

        this.transferDetails = computed(() => {
            return {
                queues: queues,
                agents: agents,
            } as IAxisTransferDetails;
        });

        this.conversationInformation = toSignal(
            this.conversationService.conversationInformation$.pipe(
                tap(() => {
                    this.isConversationInformationLoading.set(false);
                }),
            ),
        );

        this.chatItem = toSignal(
            this.chatItemService.chatItem$.pipe(
                map(chatItem => {
                    const result: IChatItem = {
                        channelId: chatItem.channelId.toString(),
                        primaryChannelType: chatItem.primaryChannelType,
                    };

                    this.mediaType.set(chatItem.primaryMediaType);

                    return result;
                }),
                tap(() => {
                    this.isChatItemLoading.set(false);
                }),
            ),
            {
                initialValue: {
                    channelId: '0',
                    primaryChannelType: '',
                } as IChatItem,
            },
        );
    }

    ngOnInit() {
        this.connectionStateAndWorkItemId$ =
            this.chatItemService.workItemId$.pipe(
                filter(ci => !!ci),
                switchMap(workItemId =>
                    this.conversationService.conversationInformation$.pipe(
                        map(ci => ({ state: ci.state, workItemId })),
                    ),
                ),
            );
    }

    sendTyping() {
        this.chatConnectionService.sendTyping(
            this.conversationService.getConversationId(),
        );
    }

    showTemplates() {
        this.showTemplatesEmitter.emit(true);
    }

    onSelectedQueue(queue: ITransferQueue) {
        this.conversationService.releaseBackToQueue(
            this.conversationService.getConversationId(),
            queue.id,
        );
    }

    private initializeAgents(
        businessUnitId: string,
    ): Observable<ITransferAgent[]> {
        return this.agentApiService.getByBusinessUnit(businessUnitId).pipe(
            map(agents =>
                agents.map(
                    agent =>
                        ({
                            id: agent.id,
                            name: agent.name,
                            status: agent.state || 'Unknown',
                        }) as ITransferAgent,
                ),
            ),
        );
    }

    private initializeQueues(
        conversationId: string,
        queueId: string,
    ): Observable<ITransferQueue[]> {
        return this.workflowApiService.getWorkflowQueues(conversationId).pipe(
            map(queues =>
                queues
                    .map(queue => ({
                        id: queue.id,
                        name: queue.name,
                        routingMode: queue.routingMode.toString(),
                        isSourceQueue: queue.id === queueId,
                    }))
                    .sort((a, b) =>
                        a.isSourceQueue === b.isSourceQueue
                            ? 0
                            : a.isSourceQueue
                              ? -1
                              : 1,
                    ),
            ),
        );
    }
}
