import { Observable, forkJoin, map, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { RbacApiService } from '../../../../../api';
import { RonaType, RonaTypes } from '../../../../../models';
import { RonaDataService } from './rona-data.service';

@Injectable({
    providedIn: 'root',
})
export class RonaServiceMG {
    constructor(
        private rbacApiService: RbacApiService,
        private ronaDataService: RonaDataService, // Inject the new service
    ) {}

    get getRona(): Observable<Map<RonaType, number>> {
        return forkJoin(
            RonaTypes.map(rona => {
                return this.rbacApiService
                    .getRona(rona)
                    .pipe(
                        map(
                            r =>
                                [rona, r.timeoutInSeconds] as [
                                    RonaType,
                                    number,
                                ],
                        ),
                    );
            }),
        ).pipe(
            map(results => new Map<RonaType, number>(results)),
            tap(map => this.ronaDataService.setRonaConfiguration(map)),
        );
    }
}
