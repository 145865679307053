<div class="row">
    <div class="col-6 d-flex flex-column justify-content-start">
        <div class="d-flex align-items-start">
            <chat-customer-name />
        </div>
        <div class="d-flex align-items-start">
            <chat-channel-name
                [channelId]="channelId"
                [primaryChannelType]="primaryChannelType"
            />
        </div>
        <div class="d-flex align-items-start">
            <chat-queue-name [queueName]="queueName" [channelId]="channelId" />
        </div>
        <div class="d-flex align-items-start">
            <div class="d-flex align-items-end">
                <!--COMMENTED OUT JUST FOR THIS PR AS IT IS NOT IN V1 -->

                <!-- <chat-status /> -->
            </div>
            <div class="d-flex align-items-end">
                <!--COMMENTED OUT JUST FOR THIS PR AS IT IS NOT IN V1 -->

                <!-- <chat-assigned /> -->
            </div>
        </div>
    </div>
    <div
        class="col-6 d-flex flex-column justify-content-between align-items-end"
    >
        <div class="d-flex align-items-end">
            <chat-top-right [mediaType]="mediaType()" />
        </div>
        <div class="d-flex align-items-end" style="visibility: hidden">
            Row 2, Col 2
        </div>
        <div class="d-flex align-items-end" style="visibility: hidden">
            Row 3, Col 2
        </div>
        <div class="d-flex align-items-end">
            <chat-header-buttons
                (openModalEmitter)="openModal()"
                (closeModalEmitter)="closeModal()"
                [transferDetails]="transferDetails()"
                (selectedQueue)="onSelectedQueue($event)"
                aria-label="Header buttons"
            />
        </div>
    </div>
</div>
