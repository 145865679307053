import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from './../../environments';
import {
    ICallState,
    IVoiceLogin,
    IVoiceServers,
    IVoiceStunServers,
    IVoiceCommand,
    IOperatorCLIResponse,
    ICostCentres,
} from './../../models';

import { LoggerService, SettingsService } from './../../services';
import { Observable } from 'rxjs';
import { IVoiceChannel } from '../../models/voice/voice-channels';

@Injectable({
    providedIn: 'root',
})
export class VoiceApiService {
    constructor(
        private logger: LoggerService,
        private http: HttpClient,
        private settingsService: SettingsService,
    ) {}
    operatorCLI = signal<string>('');
    costCentres = signal<ICostCentres>;

    changeCallState(params: ICallState) {
        this.http
            .patch<void>(`${environment.voiceUrl}/call`, params)
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Voice Service (Change call state) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Voice Service (Change call state) -> Failed',
                        error,
                    );
                },
            });
    }

    callCommand(params: IVoiceCommand) {
        this.http
            .patch<void>(`${environment.voiceUrl}/call/command`, params)
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Voice Service (Transfer call) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Voice Service (Transfer call) -> Failed',
                        error,
                    );
                },
            });
    }

    getAsteriskServers() {
        this.http
            .get<IVoiceServers>(`${environment.voiceUrl}/data/asterisk/servers`)
            .subscribe({
                next: asteriskServer => {
                    this.settingsService.setAsteriskServers(asteriskServer);
                    this.logger.debug(
                        'Voice Service (Get Asterisk servers) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Voice Service (Get Asterisk servers) -> Failed',
                        error,
                    );
                },
            });
    }

    getAsteriskStunServers() {
        this.http
            .get<IVoiceStunServers>(
                `${environment.voiceUrl}/data/asterisk/stun`,
            )
            .subscribe({
                next: stunServers => {
                    this.settingsService.setAsteriskStunServers(stunServers);
                    this.logger.debug(
                        'Voice Service (Get Asterisk stun servers) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Voice Service (Get Asterisk stun servers) -> Failed',
                        error,
                    );
                },
            });
    }

    getAsteriskLogin() {
        this.http
            .get<IVoiceLogin>(`${environment.voiceUrl}/data/asterisk/user-info`)
            .subscribe({
                next: login => {
                    this.settingsService.setAsteriskLogin(login);
                    this.logger.debug(
                        'Voice Service (Get Asterisk login) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Voice Service (Get Asterisk login) -> Failed',
                        error,
                    );
                },
            });
    }

    getOperatorCLI() {
        this.http
            .get<IOperatorCLIResponse>(
                `${environment.voiceUrl}/data/operator/cli`,
            )
            .subscribe({
                next: response => {
                    if (response.isSuccess && response.data.length > 0) {
                        const cli = response.data[0].presCLI_value;
                        this.logger.debug(
                            'Voice Service (Get operator cli) -> Successful',
                            cli,
                        );
                        this.operatorCLI.set(cli);
                    } else {
                        this.logger.warn(
                            'Voice Service (Get operator cli) -> No CLI found',
                        );
                        this.operatorCLI.set('');
                    }
                },
                error: error => {
                    this.logger.error(
                        'Voice Service (Get operator cli) -> Failed',
                        error,
                    );
                    this.operatorCLI.set('');
                },
            });
    }

    getCostCentres(): Observable<ICostCentres> {
        return this.http.get<ICostCentres>(
            `${environment.voiceUrl}/data/customer/costcentre`,
            {},
        );
    }

    getVoiceChannels(): Observable<IVoiceChannel[]> {
        return this.http.get<IVoiceChannel[]>(
            `${environment.rbacUrl}/channel-providers/voice/configurations`,
            {},
        );
    }
}
