import { CommonModule } from '@angular/common';
import { Component, computed, input, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import {
    IDropdown,
    TelXLButtonComponent,
    TelXLDropdownComponent,
} from '@dxp/elements';
import { AgentApiService } from '@dxp/shared/api';
import { secondsToDuration } from '@dxp/shared/helpers';
import { IWorkItem, IWrap } from '@dxp/shared/models';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import {
    CacheService,
    HeartbeatService,
    SettingsService,
    WorkItemService,
} from '@dxp/shared/services';
import { Duration } from 'date-fns';

@Component({
    selector: 'telxl-shared-wrap',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TelXLButtonComponent,
        FormatDurationPipe,
        TelXLDropdownComponent,
    ],
    templateUrl: './wrap.component.html',
    styleUrl: './wrap.component.scss',
})
export class TelXLWrapComponent implements OnInit {
    workItemId = input.required<string>();

    _now = signal<Date>(new Date());

    wrapDetails!: IWrap;
    taskDetails = '';

    _workItem = computed<IWorkItem>(() => {
        const workItemId = this.workItemId();
        return this.workItemService
            .workItemsSignal()
            .find(item => item.workItemId === workItemId) as IWorkItem;
    });

    _wrapCountdownSeconds = computed(() => {
        const workItem = this.workItem();
        const lastChange = new Date(workItem.lastStateChangeDate);
        const diff = Math.round(
            (this._now().getTime() - lastChange.getTime()) / 1000,
        );

        if (
            this.wrapDetails.enabled &&
            workItem.wrapupDurationSeconds - diff <= 0
        ) {
            this.complete();
        }

        return this.wrapDetails.enabled
            ? workItem.wrapupDurationSeconds - diff
            : diff;
    });

    constructor(
        private agentApiService: AgentApiService,
        private router: Router,
        private settingsService: SettingsService,
        private workItemService: WorkItemService,
        private cacheService: CacheService,
        private heartbeatService: HeartbeatService,
    ) {
        this._now = this.heartbeatService.lastHeartbeat;
    }

    ngOnInit() {
        this.wrapDetails = this.settingsService.getWrapDetails();
    }

    get workItem() {
        return this._workItem;
    }

    extend() {
        const workItem = this.workItem();
        this.agentApiService.resetWrapup(workItem.workItemId);
    }

    complete() {
        const workItem = this.workItem();
        this.agentApiService.completeWorkItem({
            notes: this.notes ? this.notes : '',
            workItemId: workItem.workItemId,
            closureCode:
                this.selectedCode && this.selectedCode.trim()
                    ? this.selectedCode
                    : '<Default>',
        });

        this.router.navigate(['/dashboard']);
    }

    get canExtend(): boolean {
        const workItem = this.workItem();

        return workItem.remainingWrapUpResets > 0;
    }

    get canComplete(): boolean {
        const workItem = this.workItem();

        return (
            !workItem.forceCompletionCodeSelection || this.selectedCode !== ''
        );
    }

    getResetDescription(): string {
        const workItem = this.workItem();
        if (workItem.remainingWrapUpResets > 1) {
            return `${workItem.remainingWrapUpResets} remaining resets`;
        } else if (workItem.remainingWrapUpResets === 1) {
            return `${workItem.remainingWrapUpResets} remaining reset`;
        }
        return '';
    }

    get wrapCountdown(): Duration {
        return secondsToDuration(this._wrapCountdownSeconds());
    }

    get progress(): number {
        const workItem = this.workItem();

        const remainingTime =
            workItem.wrapupDurationSeconds -
            Math.round(
                (this._now().getTime() -
                    new Date(workItem.lastStateChangeDate).getTime()) /
                    1000,
            );
        return (remainingTime / this.wrapDetails.timeoutSeconds) * 100;
    }

    get menuItems(): IDropdown<string>[] {
        const workItem = this.workItem();

        return (workItem.usableClosureCodes ?? [])
            .map(code => ({
                label: code,
                value: code,
                disabled: false,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    onCodeChange(code: string | null): void {
        const workItemId = this.workItemId();
        this.cacheService.saveToCache(`wrap-${workItemId}-code`, code);
    }

    onNotesChange(value: string): void {
        const workItemId = this.workItemId();
        console.error(workItemId);
        this.cacheService.saveToCache(`wrap-${workItemId}-notes`, value);
    }

    get notes(): string {
        const workItemId = this.workItemId();
        return this.cacheService.getFromCache(`wrap-${workItemId}-notes`) ?? '';
    }

    get selectedCode(): string {
        const workItemId = this.workItemId();
        return this.cacheService.getFromCache(`wrap-${workItemId}-code`) || '';
    }
}
