import {
    computed,
    Injectable,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IContactSelected } from '../../models/contact/contact-selected';
import { ICostCentres } from '../../models/voice/voice-costcentre';
import { IVoiceChannel } from '../../models/voice/voice-channels';
import { VoiceApiService } from '../../api/voice/voice.api.service';
import { UserService } from '../user/user.service';
import { IUser } from '../../models/auth/user';
import { IInternalHistoricalWorkItems } from '../../models/workitem/history';
import { AgentApiService } from '../../api/agent/agent.api.service';
import { IWorkItem } from '../../models/workitem/workitem';
import { WorkItemService } from '../workitem/workitem.service';

export interface IContactDisplay {
    parent: string;
    display: boolean;
    title: string;
    action: string;
}

@Injectable({
    providedIn: 'root',
})
export class VoiceService {
    constructor(
        private voiceApiService: VoiceApiService,
        private userService: UserService,
        private agentApiService: AgentApiService,
        private workItemService: WorkItemService,
    ) {
        this.user = this.userService.user();
    }

    user!: IUser;

    contacts: WritableSignal<IContactDisplay> = signal<IContactDisplay>({
        display: false,
        title: '',
        parent: '',
        action: '',
    });

    setContacts(
        display: boolean,
        title: string,
        parent: string,
        action: string,
    ) {
        this.contacts.set({ display, title, parent, action });
    }

    workItemId: string | null = null;

    _workItem = computed<IWorkItem | null>(() => {
        const workItemId = this.workItemId;
        if (!workItemId) return null;
        return (
            this.workItemService
                .workItemsSignal()
                .find(item => item.workItemId === workItemId) || null
        );
    });

    selectedContact: WritableSignal<IContactSelected | null> = signal(null);

    panelHighlighted = signal('main');

    callerHold = signal(false);

    get workItem() {
        return this._workItem;
    }

    get displayContacts() {
        return this.contacts().display;
    }

    setWorkItem(workItemId: string) {
        this.workItemId = workItemId;
    }

    contactSelected(contact: IContactSelected) {
        this.selectedContact.set(contact);
    }

    updateContacts(partialUpdate: Partial<IContactDisplay>) {
        this.contacts.update(current => ({ ...current, ...partialUpdate }));
    }

    getCostCentres(): Signal<ICostCentres | null> {
        return toSignal(this.voiceApiService.getCostCentres(), {
            initialValue: null,
        });
    }

    getVoiceChannels(): Signal<IVoiceChannel[] | null> {
        return toSignal(this.voiceApiService.getVoiceChannels(), {
            initialValue: null,
        });
    }

    getRecentCalls(): Signal<IInternalHistoricalWorkItems | null> {
        return toSignal(
            this.agentApiService.getWorkitemHistoryByAgent(
                this.user.id,
                true,
                ['Conversations'],
                0,
                8,
                'startTime desc',
                { voice: true },
            ),
            {
                initialValue: null,
            },
        );
    }
}
