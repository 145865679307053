import { CommonModule } from '@angular/common';
import {
    Component,
    computed,
    input,
    OnDestroy,
    OnInit,
    signal,
} from '@angular/core';
import { TelXLButtonComponent, TelXlProgressbarComponent } from '@dxp/elements';
import { secondsToDuration } from '@dxp/shared/helpers';
import {
    IConversation,
    IEmailConversationInfoEvent,
    IMetric,
    IConversationInfoEvent as IWebChatConversationInfoEvent,
    IWorkItem,
    WorkItemState,
} from '@dxp/shared/models';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import {
    HeartbeatService,
    QueueService,
    SettingsService,
    UserService,
} from '@dxp/shared/services';
import { ChatHubService, EmailHubService } from '@dxp/shared/signalr';
import { addSeconds, differenceInSeconds, Duration } from 'date-fns';
import { filter, Subscription } from 'rxjs';
import { TaskBeltService } from './../service/task-belt.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-shell-task-belt-item-panel',
    templateUrl: './task-belt-item-panel.component.html',
    styleUrls: ['./task-belt-item-panel.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TelXLButtonComponent,
        TelXlProgressbarComponent,
        FormatDurationPipe,
    ],
})
export class TaskBeltItemPanelComponent implements OnInit, OnDestroy {
    private _subscriptions = new Subscription();
    private _now = signal<Date>(new Date());

    private _conversation = computed<IConversation | undefined>(() =>
        this.workitem().conversations.find(
            c => c.conversationId === this.workitem().primaryConversationId,
        ),
    );

    private _chatConversationInfo = signal<IWebChatConversationInfoEvent>(
        {} as IWebChatConversationInfoEvent,
    );

    private _emailConversationInfo = signal<IEmailConversationInfoEvent>(
        {} as IEmailConversationInfoEvent,
    );

    workitem = input.required<IWorkItem>();
    isActiveWorkItem = signal<boolean>(false);

    constructor(
        private taskBeltService: TaskBeltService,
        private userService: UserService,
        private heartbeatService: HeartbeatService,
        private chatHubService: ChatHubService,
        private emailHubService: EmailHubService,
        private queueService: QueueService,
        private settingsService: SettingsService,
        private router: Router,
    ) {
        this._now = this.heartbeatService.lastHeartbeat;
    }

    ngOnInit() {
        if (
            this.workitem().primaryMediaType === 'Webchat' ||
            this.workitem().primaryMediaType === 'Messaging'
        ) {
            this._subscriptions.add(
                this.chatHubService.conversationInfo$
                    .pipe(
                        filter(
                            (conversationInfo: IWebChatConversationInfoEvent) =>
                                conversationInfo.conversationId ===
                                this.workitem().primaryConversationId,
                        ),
                    )
                    .subscribe(
                        (conversationInfo: IWebChatConversationInfoEvent) => {
                            this._chatConversationInfo.set(conversationInfo);
                        },
                    ),
            );
        } else if (this.workitem().primaryMediaType === 'Email') {
            this._subscriptions.add(
                this.emailHubService.conversationInfo$
                    .pipe(
                        filter(
                            (conversationInfo: IEmailConversationInfoEvent) =>
                                conversationInfo.conversationId ===
                                this.workitem().primaryConversationId,
                        ),
                    )
                    .subscribe(
                        (conversationInfo: IEmailConversationInfoEvent) => {
                            this._emailConversationInfo.set(conversationInfo);
                        },
                    ),
            );
        }

        if (
            this.workitem().primaryMediaType === 'Webchat' ||
            this.workitem().primaryMediaType === 'Messaging'
        ) {
            if (this.userService.isSupervisor()) {
                this.chatHubService.connect({
                    conversationId: this.workitem().primaryConversationId,
                    asSupervisor: true,
                });
            } else {
                this.chatHubService.peekOrConnect({
                    conversationId: this.workitem().primaryConversationId,
                });
            }
        } else if (this.workitem().primaryMediaType === 'Email') {
            this.emailHubService.peekOrConnect(
                this.workitem().primaryConversationId,
            );
        }

        this.updateIsActiveWorkItem();

        this.router.events
            .pipe(
                filter(
                    (event): event is NavigationEnd =>
                        event instanceof NavigationEnd,
                ),
            )
            .subscribe(() => this.updateIsActiveWorkItem());
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    accept() {
        this.taskBeltService.accept(this.workitem());
    }

    reject() {
        this.taskBeltService.reject(this.workitem());
    }

    reset() {
        this.taskBeltService.reset(this.workitem());
    }

    navigate() {
        if (this.state() !== 'Preview' && !this.isActiveWorkItem()) {
            this.taskBeltService.navigate(this.workitem());
        }
    }

    readonly ronaCountdown = computed<IMetric>(() => {
        const workitem = this.workitem();
        const mediaType = workitem.primaryMediaType;
        const conversation = this._conversation();
        const now = this._now();

        const defaultMetric: IMetric = {
            percent: 100,
            states: [
                { value: 33, state: 'warning' },
                { value: 66, state: 'success' },
            ],
        };

        if (mediaType === 'Messaging' || mediaType === 'Webchat') {
            const chatInfo = this._chatConversationInfo();
            if (!chatInfo?.ronaTimerExpiresAt || !conversation) {
                return defaultMetric;
            }

            const expiresAt = new Date(chatInfo.ronaTimerExpiresAt);
            return this.calculateCountdownMetric(
                workitem.createdAt,
                expiresAt,
                now,
            );
        }

        if (mediaType === 'Email') {
            const emailInfo = this._emailConversationInfo();
            if (!emailInfo?.previewTimerExpiresAt || !conversation) {
                return defaultMetric;
            }

            const expiresAt = new Date(emailInfo.previewTimerExpiresAt);
            return this.calculateCountdownMetric(
                workitem.createdAt,
                expiresAt,
                now,
            );
        }

        if (mediaType === 'Voice') {
            const expiresAt = addSeconds(workitem.createdAt, 20);
            return this.calculateCountdownMetric(
                workitem.createdAt,
                expiresAt,
                now,
            );
        }

        return defaultMetric;
    });

    readonly wrapCountdown = computed<{
        duration: Duration;
        metric: IMetric;
    }>(() => {
        const workitem = this.workitem();
        const now = this._now();

        const remainingTime =
            workitem.wrapupDurationSeconds -
            Math.round(
                (now.getTime() -
                    new Date(workitem.lastStateChangeDate).getTime()) /
                    1000,
            );

        const timeout = this.settingsService.getWrapDetails().timeoutSeconds;

        return {
            duration: secondsToDuration(remainingTime),
            metric: {
                percent: Math.min(
                    Math.max(0, (remainingTime / timeout) * 100),
                    100,
                ),
                states: [
                    { value: 33, state: 'warning' },
                    { value: 66, state: 'success' },
                ],
            },
        };
    });

    readonly canExtend = computed<boolean>(
        () => this.workitem().remainingWrapUpResets > 0,
    );

    readonly state = computed<WorkItemState>(
        () => this.workitem().workItemState,
    );

    readonly queueName = computed<string>(() => {
        const workitem = this.workitem();
        const primaryId = workitem.primaryConversationId;

        const conversationQueueName = workitem.conversations?.find(
            c => c.conversationId === primaryId,
        )?.queueName;

        if (conversationQueueName) return conversationQueueName;

        const queue = this.queueService
            .queues()
            .find(
                q =>
                    q.hasActivity && q.activity().some(a => a.id === primaryId),
            );

        return queue?.name ?? 'Unknown Queue';
    });

    readonly taskItemClass = computed<string[]>(() => {
        const classes: string[] = [];

        const workitem = this.workitem();
        const mediaType = workitem.primaryMediaType;
        const isActive = this.isActiveWorkItem();
        const state = workitem.workItemState;

        if (isActive) {
            classes.push('selected');
        } else if (state === 'Afterwork') {
            classes.push('pulsing-border');
        }

        switch (mediaType) {
            case 'Voice':
                classes.push('voice');
                break;
            case 'Webchat':
                classes.push('webchat');
                break;
            case 'Email':
                classes.push('email');
                break;
            case 'Messaging':
                classes.push('messaging');
                break;
        }

        if (state !== 'Preview' && !isActive) {
            classes.push('navigate');
        }

        return classes;
    });

    readonly iconClass = computed<string[]>(() => {
        const classes: string[] = [];

        const workitem = this.workitem();
        const mediaType = workitem.primaryMediaType;
        const isActive = this.isActiveWorkItem();

        classes.push(isActive ? 'fa-solid' : 'fa-light');

        switch (mediaType) {
            case 'Voice':
                classes.push('fa-phone');
                break;
            case 'Webchat':
                classes.push('fa-message-lines');
                break;
            case 'Email':
                classes.push('fa-envelope');
                break;
            case 'Messaging':
                classes.push('fa-shared-nodes');
                break;
        }

        return classes;
    });

    private calculateCountdownMetric(
        start: Date,
        end: Date,
        now: Date,
    ): IMetric {
        const total = differenceInSeconds(end, start);
        const remaining = differenceInSeconds(end, now);
        const percent = Math.min(Math.max(0, (remaining / total) * 100), 100);

        return {
            percent,
            states: [
                { value: 33, state: 'warning' },
                { value: 66, state: 'success' },
            ],
        };
    }

    private updateIsActiveWorkItem() {
        const url = this.router.url;
        const match = url.match(/^\/comms\/(voice|chat|email)\/([\w-]+)$/);
        const currentId = match?.[2];
        const isActive = currentId === this.workitem().workItemId;

        this.isActiveWorkItem.set(isActive);
    }
}
