import { CommonModule } from '@angular/common';
import {
    Component,
    ComponentRef,
    computed,
    input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { IWorkItem, MediaType, TaskOrder } from '@dxp/shared/models';
import { TaskBeltItemPanelComponent } from './../item/task-belt-item-panel.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { VoiceNotificationComponent } from '@dxp/voice';
import { toObservable } from '@angular/core/rxjs-interop';
import { map, pairwise, startWith, Subscription } from 'rxjs';

@Component({
    selector: 'app-shell-task-belt-container-panel',
    templateUrl: './task-belt-container-panel.component.html',
    styleUrls: ['./task-belt-container-panel.component.scss'],
    standalone: true,
    imports: [CommonModule, TaskBeltItemPanelComponent],
    animations: [
        trigger('dropInOut', [
            transition(':enter', [
                style({ opacity: 0, transform: '{{transformStart}}' }),
                animate(
                    '0.5s ease-out',
                    style({ opacity: 1, transform: 'translateY(0)' }),
                ),
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateY(0)' }),
                animate(
                    '0.5s ease-in',
                    style({ opacity: 0, transform: 'translateY(20px)' }),
                ),
            ]),
        ]),
    ],
})
export class TaskBeltContainerPanelComponent implements OnInit, OnDestroy {
    @ViewChild('voice', { read: ViewContainerRef, static: true })
    voice!: ViewContainerRef;
    private voiceRef?: ComponentRef<VoiceNotificationComponent>;
    private _subscriptions = new Subscription();

    ngOnInit() {
        this._subscriptions.add(
            this.workitems$
                .pipe(
                    map(items =>
                        items.filter(
                            w =>
                                w.primaryMediaType === 'Voice' &&
                                w.workItemState === 'Preview',
                        ),
                    ),
                    startWith([]),
                    pairwise(),
                    map(([prev, curr]) => {
                        const prevIds = new Set(prev.map(w => w.workItemId));
                        return curr.filter(w => !prevIds.has(w.workItemId));
                    }),
                )
                .subscribe(newVoiceItems => {
                    for (const workItem of newVoiceItems) {
                        this.showVoiceNotification(workItem);
                    }
                }),
        );
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    workitems = input.required<IWorkItem[]>();
    workitems$ = toObservable(this.workitems);

    orderedWorkitems = computed<IWorkItem[]>(() =>
        this.workitems().sort((a, b) => {
            if (this.mediaTypeMapping(a) === this.mediaTypeMapping(b)) {
                return a.createdAt.getTime() - b.createdAt.getTime();
            }
            return this.mediaTypeMapping(a) - this.mediaTypeMapping(b);
        }),
    );

    showVoiceNotification(workItem: IWorkItem) {
        if (!this.voiceRef) {
            this.voice.clear();
            this.voiceRef = this.voice.createComponent(
                VoiceNotificationComponent,
            );
            this.voiceRef.instance.close.subscribe(() => {
                this.closeVoiceNotification();
            });

            this.voiceRef.instance.workItemId = workItem.workItemId;
            this.voiceRef.instance.navigateTo = 'comms/voice';
            this.voiceRef.instance.timeout = 20;
        }
    }
    closeVoiceNotification() {
        if (this.voiceRef) {
            this.voiceRef.destroy();
            this.voiceRef = undefined;
        }
    }

    private mediaTypeMapping(workitem: IWorkItem): TaskOrder {
        return (
            this.mediaTypeMappings[workitem.primaryMediaType] ??
            TaskOrder.Unknown
        );
    }

    private mediaTypeMappings: Partial<Record<MediaType, TaskOrder>> = {
        Voice: TaskOrder.Voice,
        Webchat: TaskOrder.Webchat,
        Messaging: TaskOrder.Messaging,
        Email: TaskOrder.Email,
    };
}
