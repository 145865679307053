import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { SipService } from '@services/sip/sip.service';
import { TaskProgressComponent } from '@components/task-progress/task-progress.component';
import { SettingsService } from '@services/settings/settings.service';
import { IIncomingTask } from '@models/task/task';

@Component({
    selector: 'task-belt-item',
    standalone: true,
    imports: [CommonModule, TaskProgressComponent],
    templateUrl: './task-belt-item.component.html',
    styleUrl: './task-belt-item.component.scss',
})
export class TaskBeltItemComponent {
    @Input() createdAtActual = new Date();
    @Input() isSelected = false;
    @Input() timeout = 0;
    @Input() task!: IIncomingTask;
    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

    stopProgress = false;
    displayTaskButtons = false;

    constructor(
        private sipService: SipService,
        private router: Router,
        private settingsService: SettingsService,
    ) {}

    ngOnInit() {
        this.displayTaskButtons =
            this.task.workItemState === 'Preview' && this.timeout > 0;
    }

    onClick() {
        this.setTask();
        this.clicked.emit();
        this.stopProgress = true;
        this.isSelected = true;
        this.router.navigate([this.task.navigation]);
    }

    onAcceptClick(): void {
        if (this.task.mediaType === 'Voice') {
            this.sipService.answerCall();
        }
        this.setTask;
        this.isSelected = true;
        this.displayTaskButtons = false;
        this.router.navigate([this.task.navigation]);
        this.timeout = 0;
    }

    onRejectClick(): void {
        if (this.task.mediaType === 'Voice') {
            this.sipService.hangUp();
        }
        this.stopProgress = true;
        this.displayTaskButtons = false;
    }

    setTask(): void {
        if (this.task) {
            switch (this.task.mediaType) {
                case 'Voice':
                    this.settingsService.setIncomingVoiceTask(this.task);
                    break;
                case 'Webchat':
                    this.settingsService.setIncomingWebchatTask(this.task);
                    break;
            }
        }
    }
}
