import { Component, Input } from '@angular/core';

@Component({
  selector: 'chat-channel-name',
  templateUrl: './channel-name.component.html',
  standalone: true,
})
export class ChannelNameComponent {
  @Input({required:true}) primaryChannelType!: string;
  @Input({required:true}) channelId!: string;
}