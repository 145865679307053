import {
    BehaviorSubject,
    Observable,
    OperatorFunction,
    catchError,
    delay,
    filter,
    map,
    of,
    retry,
    throwError,
} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@dxp/shared/services';
import { TranslateTextResp } from '../../models/translation';
import { TranslateToChannel } from '../../models/translation.interface';
import { environment } from '@dxp/shared/environment';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    private _isToggle = new BehaviorSubject<boolean>(false);
    private _languageCode = new BehaviorSubject<string>('');

    isToggle$ = this._isToggle.asObservable();
    languageCode$ = this._languageCode.asObservable().pipe(filter(x => !!x));

    constructor(
        private logger: LoggerService,
        private http: HttpClient,
    ) {}

    setIsTranslate(isTranslate: boolean): void {
        this._isToggle.next(isTranslate);
    }

    setLanguageCode(languageCode: string): void {
        this._languageCode.next(languageCode);
    }

    textToChannel(
        translation: TranslateToChannel[],
        channelID: number,
    ): Observable<TranslateToChannel[]>[] {
        return translation.map(x => {
            const dto = {
                texts: x.translateToChannelRequests.texts,
                sourceLang: x.translateToChannelRequests.sourceLang,
                fallbackTargetLang:
                    x.translateToChannelRequests.fallbackTargetLang,
            };

            return this.http
                .post<TranslateTextResp>(
                    `${environment.translationUrl}/text/to/channels/${channelID}`,
                    dto,
                )
                .pipe(
                    map((res: TranslateTextResp) =>
                        res.translations.map(t => {
                            return {
                                id: x.id,
                                translateToChannelRequests: {
                                    texts: [t.text],
                                    sourceLang:
                                        x.translateToChannelRequests.sourceLang,
                                    fallbackTargetLang:
                                        x.translateToChannelRequests
                                            .fallbackTargetLang,
                                },
                            };
                        }),
                    ),
                    this.logErrorAndDelayRetry(),
                    catchError(err => {
                        this.logger.error(
                            'Retries exhausted. Returning observable error.',
                            err,
                        );

                        return throwError(
                            () =>
                                new Error(`Translation failed: ${err.message}`),
                        );
                    }),
                );
        });
    }

    private logErrorAndDelayRetry(
        retryTimes = 3,
        delayMileseconds = 2000,
    ): OperatorFunction<TranslateToChannel[], TranslateToChannel[]> {
        return retry({
            count: retryTimes,
            delay: (error, retryCount) => {
                this.logger.error('Retrying translation', error);

                const incrementalDelay = delayMileseconds * retryCount;
                this.logger.info(
                    `Retry #${retryCount} after ${incrementalDelay}ms`,
                );

                return of(null).pipe(delay(incrementalDelay));
            },
        });
    }
}
