import { Injectable } from '@angular/core';
import {
    IConversation,
    IConversationInfoEvent,
    IConversationInformation,
    IWorkItem,
    WorkItemState,
} from '@dxp/shared/models';
import { LoggerService } from '@dxp/shared/services';

import { BehaviorSubject, Observable, filter, tap } from 'rxjs';

import { ChatHelperService } from './chat-helper.service';
import { ChatOutgoingService } from './chat-outgoing.service';
import { ChatHubIncomingService } from './hub/chat-hub-incoming.service';

@Injectable({
    providedIn: 'root',
})
export class ConversationService {
    #conversationId = new BehaviorSubject<string>('');
    #conversationInfo = new BehaviorSubject<IConversationInfoEvent | undefined>(
        undefined,
    );
    #conversationInformation = new BehaviorSubject<
        IConversationInformation | undefined
    >(undefined);
    #primaryConversationId = new BehaviorSubject<string>('');

    conversationId$ = this.#conversationId.asObservable().pipe(
        filter(ci => !!ci),
        tap(value => this.log('conversationId$', value)),
    );
    conversationInfo$ = this.#conversationInfo.asObservable().pipe(
        filter(ci => !!ci),
        tap(value => this.log('conversationInfo$', value)),
    );
    conversationInformation$ = this.#conversationInformation
        .asObservable()
        .pipe(filter(ci => !!ci));
    primaryConversationId$ = this.#primaryConversationId.asObservable().pipe(
        filter(ci => !!ci),
        tap(value => this.log('primaryConversationId$', value)),
    );

    constructor(
        private logger: LoggerService,
        private chatGeneralService: ChatHelperService,
        private chatHubIncomingService: ChatHubIncomingService,
        private chatOutgoingService: ChatOutgoingService,
    ) {}

    /**
     * Get conversation information for the given primary conversation ID.
     * @param primaryConversationId The primary conversation ID.
     * @returns An observable of the conversation information event.
     */
    conversationInfo(
        primaryConversationId: string,
    ): Observable<IConversationInfoEvent> {
        return this.chatHubIncomingService.conversationInfo$.pipe(
            tap(value => {
                this.log('conversationInfo', value);
            }),
            filter(
                (conversationInfo: IConversationInfoEvent) =>
                    conversationInfo.conversationId === primaryConversationId,
            ),
        );
    }

    /**
     * Get the current conversation ID.
     * @returns The current conversation ID.
     */
    getConversationId() {
        this.log('getConversatonId', this.#conversationId.value);
        return this.#conversationId.value;
    }

    /**
     * Get the current conversation information.
     * @returns The current conversation information.
     */
    getConversationInformation() {
        this.log(
            'getConversationInformation',
            this.#conversationInformation.value,
        );
        return this.#conversationInformation.value;
    }

    /**
     * Release the conversation back to the queue.
     * @param conversationId The conversation ID.
     * @param queueId The queue ID.
     */
    releaseBackToQueue(conversationId: string, queueId: string) {
        this.chatOutgoingService.releaseBackToQueue(conversationId, queueId);
    }

    /**
     * Send a message in the conversation.
     * @param conversationId The conversation ID.
     * @param file Optional file to send with the message.
     */
    sendMessage(messages: string, file?: File) {
        this.chatOutgoingService.sendMessage(
            this.getConversationId(),
            messages,
            file,
        );
    }

    /**
     * Send a typing indicator in the conversation.
     * @param conversationId The conversation ID.
     */
    sendTyping(conversationId: string) {
        this.chatOutgoingService.sendTyping(conversationId);
    }

    /**
     * Set the conversation ID.
     * @param conversationId The conversation ID.
     */
    setConversationId(conversationId: string) {
        this.log('setConversationId', conversationId);
        if (conversationId.length === 0) {
            this.logger.error('Conversation ID is not defined');
            throw new Error('Conversation ID is not defined');
        }
        this.chatGeneralService.updateBehaviorSubject(
            this.#conversationId,
            conversationId,
        );
    }

    /**
     * Set the conversation information event.
     * @param conversationInfo The conversation information event.
     */
    setConversationInfo(conversationInfo: IConversationInfoEvent) {
        this.log('setConversationInfo', conversationInfo);
        if (!conversationInfo || Object.keys(conversationInfo).length === 0) {
            this.logger.error('Conversation info is not defined');
            throw new Error('Conversation info is not defined');
        }
        this.chatGeneralService.updateBehaviorSubject(
            this.#conversationInfo,
            conversationInfo,
        );
    }

    /**
     * Set the conversation information.
     * @param workItem The work item.
     * @param conversationInfo The conversation information event.
     */
    setConversationInformation(
        workItem: IWorkItem,
        conversationInfo: IConversationInfoEvent,
    ) {
        this.log('setConversationInformation', { workItem, conversationInfo });
        if (!workItem) {
            this.logger.error('Work item is not defined');
            throw new Error('Work item is not defined');
        }
        if (!conversationInfo) {
            this.logger.error('Conversation info is not defined');
            throw new Error('Conversation info is not defined');
        }
        if (!workItem.workItemState) {
            this.logger.error('Work item state is not defined');
            throw new Error('Work item state is not defined');
        }
        if (!workItem.conversations) {
            this.logger.error('Conversations are not defined');
            throw new Error('Conversations are not defined');
        }
        if (!workItem.primaryConversationId) {
            this.logger.error('Primary conversation ID is not defined');
            throw new Error('Primary conversation ID is not defined');
        }

        const newConversationInformation: IConversationInformation = {
            state: workItem.workItemState ?? ({} as WorkItemState),
            conversation: workItem.conversations.find(
                c => c.conversationId === workItem.primaryConversationId,
            ) as IConversation,
            info: conversationInfo,
        };

        this.chatGeneralService.updateBehaviorSubject(
            this.#conversationInformation,
            newConversationInformation,
        );
    }

    /**
     * Set the primary conversation ID.
     * @param primaryConversationId The primary conversation ID.
     */
    setPrimaryConversationId(primaryConversationId: string) {
        this.log('setPrimaryConversationId', primaryConversationId);
        if (primaryConversationId.length === 0) {
            this.logger.error('Primary Conversation ID is not defined');
            throw new Error('Primary Conversation ID is not defined');
        }
        this.chatGeneralService.updateBehaviorSubject(
            this.#primaryConversationId,
            primaryConversationId,
        );
    }

    /**
     * Private helper to log messages.
     * @param method The method name.
     * @param value The value to log.
     */
    private log(method: string, value: any) {
        this.logger.debug(`${method}:`, value);
    }
}
