/**
 * ChatHubWrapperService is an Angular service that wraps the ChatHubService.
 * It provides observables for various chat events and methods to interact with the chat hub.
 * This service also logs debug information for each event and command.
 * 
 * The idea is to decouple the main chat hub SignalR feed and only expose incoming and outgoing functions and properties.
 */

import { Injectable } from '@angular/core';
import {
    IAxisAttachment,
    IAxisConversationID,
    IAxisMessage,
    IAxisSuggestedReply,
    IConnectCommand,
    IConversationCommand,
    IConversationEvent,
    IConversationInfoEvent,
    IDisconnectCommand,
    IErrorEvent,
    IMessage,
    IMessageCommand,
    IMessageRecommendation,
    IParticipantTypingEvent,
    IRecommendationEvent,
    ITypingCommand,
} from '@dxp/shared/models';
import { LoggerService } from '@dxp/shared/services';
import { ChatHubService } from '@dxp/shared/signalr';
import { Observable } from 'rxjs';

import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ChatHubWrapperService {
    chatEnded$: Observable<IAxisConversationID>;
    chatHistory$: Observable<IAxisMessage[]>;
    chatMessage$: Observable<IAxisMessage>;
    conversationInfo$: Observable<IConversationInfoEvent>;
    error$: Observable<IErrorEvent>;
    participantTyping$: Observable<IParticipantTypingEvent>;
    suggestedReplies$: Observable<IAxisSuggestedReply[]>;
    refresh$: Observable<IConversationEvent>;

    constructor(
        private chatHubService: ChatHubService,
        private logger: LoggerService,
    ) {
        this.chatEnded$ = this.chatHubService.chatEnded$.pipe(
            tap(event => {
                this.logger.debug('ChatHubWrapperService: chatEnded$', event);
            }),
        );
        this.chatHistory$ = this.chatHubService.chatHistory$.pipe(
            map(event => {
                return event.messages.map(message => {
                    const result: IAxisMessage = this.createAxisMessage(
                        message,
                        event.conversationId,
                    );

                    return result;
                });
            }),
            tap(event => {
                this.logger.debug('ChatHubWrapperService: chatHistory$', event);
            }),
        );
        this.chatMessage$ = this.chatHubService.chatMessage$.pipe(
            map(event =>
                this.createAxisMessage(event.message, event.conversationId),
            ),
            tap(event => {
                this.logger.debug('ChatHubWrapperService: chatMessage$', event);
            }),
        );
        this.conversationInfo$ = this.chatHubService.conversationInfo$.pipe(
            tap(event => {
                this.logger.debug(
                    'ChatHubWrapperService: conversationInfo$',
                    event,
                );
            }),
        );
        this.error$ = this.chatHubService.error$.pipe(
            tap(event => {
                this.logger.debug('ChatHubWrapperService: error$', event);
            }),
        );
        this.participantTyping$ = this.chatHubService.participantTyping$.pipe(
            tap(event => {
                this.logger.debug(
                    'ChatHubWrapperService: participantTyping$',
                    event,
                );
            }),
        );
        this.suggestedReplies$ = this.chatHubService.recommendations$.pipe(
            map(event =>
                event.recommendations.map(recommendation => {
                    return this.createAxisRecommendation(event, recommendation);
                }),
            ),
            tap(event => {
                this.logger.debug(
                    'ChatHubWrapperService: recommendations$',
                    event,
                );
            }),
        );
        this.refresh$ = this.chatHubService.refresh$.pipe(
            tap(event => {
                this.logger.debug('ChatHubWrapperService: refresh$', event);
            }),
        );
    }

    private createAxisRecommendation(
        event: IRecommendationEvent,
        recommendation: IMessageRecommendation,
    ) {
        const result: IAxisSuggestedReply = {
            conversationId: event.conversationId,
            messageId: event.messageId,
            text: recommendation.text,
            confidence: recommendation.confidence,
        };

        return result;
    }

    private createAxisMessage(
        message: IMessage,
        conversationId: string,
    ): IAxisMessage {
        return {
            attachments: message.attachments as IAxisAttachment[],
            createdAt: message.createdAt,
            detectedLanguage: message.detectedLanguage,
            id: message.id,
            isInternal: message.isInternal,
            sender: {
                id: message.sender.id,
                name: message.sender.name || '',
                role: message.sender.role,
            },
            textMessage: message.textMessage,
            translatedText: message.translatedText,
            conversationId,
        };
    }

    accept(command: IConversationCommand) {
        this.logger.debug('ChatHubWrapperService: accept', command);

        this.chatHubService.accept(command);
    }

    connect(command: IConnectCommand) {
        this.logger.debug('ChatHubWrapperService: connect', command);

        this.chatHubService.connect(command);
    }

    peekOrConnect(command: IConversationCommand) {
        this.logger.debug('ChatHubWrapperService: peekOrConnect', command);

        this.chatHubService.peekOrConnect(command);
    }

    reject(command: IConversationCommand) {
        this.logger.debug('ChatHubWrapperService: reject', command);

        this.chatHubService.reject(command);
    }

    sendDisconnect(command: IDisconnectCommand) {
        this.logger.debug('ChatHubWrapperService: sendDisconnect', command);

        this.chatHubService.sendDisconnect(command);
    }

    sendMessage(command: IMessageCommand) {
        this.logger.debug('ChatHubWrapperService: sendMessage', command);

        this.chatHubService.sendMessage(command);
    }

    sendTyping(command: ITypingCommand) {
        this.logger.debug('ChatHubWrapperService: sendTyping', command);

        this.chatHubService.sendTyping(command);
    }
}
