import { computed, Injectable, OnDestroy, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { AgentHubService } from '@signalr/agent/agent.hub.service';
import { IUser } from '@models/auth/user';
import { IRbacRole } from '@models/rbac/permissions';
import { IScope } from '@models/agent/agent-scope';
import {
    calculateUserPresence,
    IUserPresence,
} from '@models/presence/user-presence';
import { IAgentClientState } from '@models/agent/agent-state';

@Injectable({
    providedIn: 'root',
})
export class UserService implements OnDestroy {
    private subscriptions = new Subscription();
    private _permissions: Map<string, Set<string>> = new Map();

    default: IUser = {
        id: '',
        name: '',
        emailAddress: '',
        authenticated: false,
        enabled: false,
        phoneNumber: '',
        profilePictureUrl: null,
        photo: undefined,
        tenantId: '',
        displayName: '',
        jobTitle: '',
        tenants: [],
        tenant: undefined,
        scopes: [] as IScope[],
    };

    private _user = signal<IUser>(this.default);
    user = computed<IUser>(() => this._user.asReadonly()());

    private _presence = signal<IUserPresence>(
        calculateUserPresence('LoggedOut', new Date(Date.now())),
    );
    presence = computed<IUserPresence>(() => this._presence.asReadonly()());

    constructor(private agentHubService: AgentHubService) {
        const stateSubscription = this.agentHubService.agentState$.subscribe(
            (agentState: IAgentClientState) => {
                this._presence.set(
                    calculateUserPresence(
                        agentState.agentProperties.state,
                        agentState.agentProperties.stateSince,
                        agentState.agentProperties.breakName,
                        agentState.agentProperties.timedBreakExpiresAt,
                    ),
                );
            },
        );

        this.subscriptions.add(stateSubscription);
    }

    update(user: IUser) {
        this._user.set({ ...this.user(), ...user });
    }

    clear() {
        this._user.set(this.default);
        this._permissions.clear();
    }

    setPermissions(role: IRbacRole): void {
        this._permissions.clear();

        role.permissions.forEach(permission => {
            const businessEntity = permission.businessEntity.toLowerCase();
            const permissionType = permission.permissionType.toLowerCase();

            if (!this._permissions.has(businessEntity)) {
                this._permissions.set(businessEntity, new Set());
            }
            this._permissions.get(businessEntity)?.add(permissionType);
        });
    }

    hasPermissionByEntityAndType(entity: string, type: string): boolean {
        return (
            (this._permissions.has(entity) &&
                this._permissions.get(entity)?.has(type)) ||
            false
        );
    }

    hasPermission(permission: string): boolean {
        const [entity, type] = permission.toLowerCase().split('.');

        if (!entity || !type) {
            return false;
        }

        return this.hasPermissionByEntityAndType(entity, type);
    }

    getPermissionsForEntity(entity: string): Set<string> | undefined {
        return this._permissions.get(entity);
    }

    isSupervisor(): boolean {
        return this.user().scopes.some(
            scope =>
                scope.name === 'bp.access' &&
                scope.claims.some(
                    claim =>
                        claim.type === 'buzzplus.role' &&
                        claim.value === 'supervisor',
                ),
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
