<div class="d-flex align-items-center justify-content-end">
    <telxl-transfer
        [chatTransferDetails]="transferDetails()"
        (selectedQueue)="onSelectedQueue($event)"
    />

    @if (false) {
        <telxl-button
            colour="light"
            size="sm"
            (buttonClick)="sendDisconnect()"
            (keydown.enter)="sendDisconnect()"
            tabindex="1"
        >
            <i class="fa fa-language me-2"></i>
            Translate
        </telxl-button>
    }

    <telxl-button
        colour="dark"
        size="sm"
        (buttonClick)="sendDisconnect()"
        (keydown.enter)="sendDisconnect()"
        [outline]="true"
        tabindex="1"
    >
        End Chat
    </telxl-button>
</div>
