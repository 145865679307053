import { Injectable, signal } from '@angular/core';
import { ViewType } from '../../helpers/style';
import { ChannelGroupType } from '../../models/queue/queue';
import { RonaType } from '../../models/rbac/rona';
import { IVoiceLogin } from '../../models/voice/voice-login';
import { IVoiceServers } from '../../models/voice/voice-servers';
import { IVoiceStunServers } from '../../models/voice/voice-stun-servers';
import { IWrap } from '../../models/wrap/wrap';
import { RbacConversationSortType } from '../../models/rbac/conversation-sorting-mode';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private _ronaConfiguration: Map<RonaType, number> = new Map();
    private _wrapDetails!: IWrap;

    asteriskServers = signal<IVoiceServers | undefined>(undefined);
    asteriskStunServers = signal<IVoiceStunServers | undefined>(undefined);
    asteriskLogin = signal<IVoiceLogin | undefined>(undefined);

    viewType = signal<ViewType>('standard');
    channelGroupFilter = signal<ChannelGroupType | undefined>(undefined);
    conversationSortingMode = signal<RbacConversationSortType>('ByQueueTime');

    setRona(rona: RonaType, timeOut: number) {
        this._ronaConfiguration.set(rona, timeOut);
    }

    setConversationSorting(conversationSortingMode: RbacConversationSortType) {
        this.conversationSortingMode.set(conversationSortingMode);
    }

    clearChannelProviderConfigurations() {
        this._ronaConfiguration.clear();
    }

    getRona(rona: RonaType): number {
        return this._ronaConfiguration.get(rona) ?? 0;
    }

    setWrapDetails(wrap: IWrap) {
        this._wrapDetails = wrap;
    }

    getWrapDetails(): IWrap {
        return this._wrapDetails;
    }

    setAsteriskServers(servers: IVoiceServers) {
        this.asteriskServers.set(servers);
    }

    setAsteriskStunServers(servers: IVoiceStunServers) {
        this.asteriskStunServers.set(servers);
    }

    setAsteriskLogin(login: IVoiceLogin) {
        this.asteriskLogin.set(login);
    }
}
