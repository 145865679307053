import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CommonModule } from '@angular/common';

@Component({
    selector: 'trans-toggle',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './toggle.component.html',
    styleUrl: './toggle.component.scss',
})
export class ToggleComponent {
    @Input({ required: true }) displayText = 'No text provided';
    @Output() toggleOutput = new EventEmitter<boolean>();

    isToggle = false;

    getSwitchClass(): string {
        return this.isToggle
            ? 'bg-success border-success'
            : 'bg-secondary border-secondary';
    }

    toggleSwitch(): void {
        this.isToggle = !this.isToggle;
        this.toggleOutput.emit(this.isToggle);
    }
}
