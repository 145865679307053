import { Injectable } from '@angular/core';
import {
    ChannelType,
    IConversation,
    IWorkItem,
    MediaType,
    WorkItemState,
} from '@dxp/shared/models';
import { LoggerService, UserWorkitemService } from '@dxp/shared/services';
import { BehaviorSubject, filter, map, Observable, tap } from 'rxjs';

import { ChatHelperService } from './chat-helper.service';

export interface IAxisChatItem {
    acceptedAt?: Date;
    businessUnitId: string;
    channelId: number;
    conversations: IConversation[];
    createdAt: Date;
    customerId: string | null;
    forceCompletionCodeSelection: boolean;
    lastStateChangeDate: Date;
    primaryChannelType: ChannelType;
    primaryConversationId: string;
    primaryMediaType: MediaType;
    queueName: string;
    remainingWrapUpResets: number;
    usableClosureCodes: string[];
    workItemId: string;
    workItemState: WorkItemState;
    wrapupDurationSeconds: number;
}

@Injectable({
    providedIn: 'root',
})
export class ChatItemService {
    #chatItem = new BehaviorSubject<IAxisChatItem | undefined>(undefined);
    #chatItemId = new BehaviorSubject<string | undefined>(undefined);

    chatItem$ = this.#chatItem.asObservable().pipe(
        filter(wi => !!wi),
        tap(value => this.log('workItem$', value)),
    );
    workItemId$ = this.#chatItemId.asObservable().pipe(
        filter(wi => !!wi),
        tap(value => this.log('workItemId$', value)),
    );

    constructor(
        private logger: LoggerService,
        private chatGeneralService: ChatHelperService,
        private userWorkItemService: UserWorkitemService,
    ) {}

    setWorkItem(workItem: IWorkItem) {
        this.log('setWorkItem', workItem);
        if (!workItem) {
            this.logger.error('Work item is not defined');
            throw new Error('Work item is not defined');
        }

        const axisChatItem: IAxisChatItem = {
            ...workItem,
            businessUnitId: workItem.businessUnitId || '',
        };

        this.chatGeneralService.updateBehaviorSubject(
            this.#chatItem,
            axisChatItem,
        );
    }

    setWorkItemId(workItemId: string) {
        this.log('setWorkItemId', workItemId);
        if (workItemId.length === 0) {
            this.logger.error('Work item ID is not defined');
            throw new Error('Work item ID is not defined');
        }

        this.chatGeneralService.updateBehaviorSubject(
            this.#chatItemId,
            workItemId,
        );
    }

    getChatItemWith(id: string): Observable<IWorkItem> {
        if (id.length === 0) {
            this.logger.error('Work item is not defined');
            throw new Error('Work item is not defined');
        }

        return this.userWorkItemService.listenFilteredByLength().pipe(
            filter(wis => wis.length > 0),
            map(wis => wis.find(w => w.workItemId === id)),
            filter(wi => !!wi),
        );
    }

    private log(method: string, value: any) {
        this.logger.debug(`${method}:`, value);
    }
}
