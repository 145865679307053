import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { Route } from '@angular/router';
import { NotFoundComponent } from './errors/not-found/item-not-found.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('@dxp/dashboard').then(m => m.DashboardRoutes),
        canActivate: [AutoLoginPartialRoutesGuard],
    },
    {
        path: 'performance',
        loadChildren: () =>
            import('@dxp/performance').then(m => m.PerformanceRoutes),
        canActivate: [AutoLoginPartialRoutesGuard],
    },
    {
        path: 'debug',
        loadChildren: () => import('@dxp/debug').then(m => m.DebugRoutes),
        canActivate: [AutoLoginPartialRoutesGuard],
    },
    {
        path: 'comms',
        loadChildren: () =>
            import('./communications/communication.routes').then(
                m => m.CommunicationRoutes,
            ),
        canActivate: [AutoLoginPartialRoutesGuard],
    },
    {
        path: 'examples',
        loadChildren: () => import('@dxp/examples').then(m => m.ExamplesRoutes),
        canActivate: [AutoLoginPartialRoutesGuard],
    },
    {
        path: 'auth-callback',
        component: AuthCallbackComponent,
    },
    {
        path: 'not-found',
        component: NotFoundComponent,
    },
];
