@if (footerInitialise$ | async) {
    <chat-suggested-replies
        [suggestedReplies]="replies()"
        (reply)="useSuggestedReply($event)"
        aria-live="polite"
        title="Suggested replies"
        aria-label="Suggested replies"
    />
}

<div class="d-flex" role="form" aria-label="Message Form">
    <div class="flex-grow-1 m-2">
        <textarea
            class="form-control border-0 w-100"
            style="min-height: 7.4rem; resize: none"
            #messageInput
            [ngModel]="inputText()"
            (ngModelChange)="onTextChange($event)"
            (input)="sendTyping()"
            placeholder="Type your message here..."
            aria-label="Message input"
            aria-required="true"
            title="Type your message here"
        ></textarea>
    </div>

    <div
        class="d-flex flex-column justify-content-end align-items-end justify-content-between ms-2 pe-2 pt-2"
    >
        <telxl-button
            colour="light"
            (buttonClick)="showTemplates()"
            (keydown.enter)="showTemplates()"
            aria-label="Show templates button"
            title="Show templates"
        >
            <i [class]="toggleIcons()"></i>
            Templates
        </telxl-button>

        <div class="d-flex flex-column align-items-end">
            <div class="d-flex align-items-center mt-2 mb-2">
                <telxl-attachment
                    class="me-2"
                    aria-label="Attachment button"
                    title="Add attachment"
                />
                <telxl-button
                    colour="primary"
                    [outline]="true"
                    [disabled]="isDisabled()"
                    (buttonClick)="sendMessage(inputText())"
                    (keydown.enter)="sendMessage(inputText())"
                    tabindex="0"
                    aria-label="Send message button"
                    title="Send message"
                >
                    <div class="d-flex align-items-center">
                        <i class="fa fa-send me-2" aria-hidden="true"></i>
                        <span>Send</span>
                    </div>
                </telxl-button>
            </div>
        </div>
    </div>
</div>
