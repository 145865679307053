<div class="p-1">
    @if (searchPreview) {
        <div tabindex="0" class="d-flex flex-column px-5 pb-2 search">
            <div
                class="d-flex align-items-center justify-content-between pt-3 pb-2 sticky-top bg-white"
            >
                <div class="font-lg weight-medium">
                    {{ searchPreview.title }}
                </div>

                <telxl-button
                    colour="primary"
                    (buttonClick)="onSearchPreviewClick()"
                    (keydown.enter)="onSearchPreviewClick()"
                >
                    Use
                </telxl-button>
            </div>
            <div class="mt-2">
                {{ searchPreview.item }}
            </div>
        </div>
    }
</div>
