import { CommonModule } from '@angular/common';
import { Component, input, OnInit, output, signal } from '@angular/core';
import { TelXLButtonComponent } from '@dxp/elements';
import { ITemplate } from '@dxp/shared/models';

import { Observable, tap } from 'rxjs';

import { TemplateDataService } from '../../services/template/template-data.service';
import { InputSearchComponent } from './input-search/input-search.component';
import { TelXLSearchListComponent } from './search-list/search-list.component';
import { SearchPreviewComponent } from './search-preview/search-preview.component';

@Component({
    selector: 'chat-template',
    standalone: true,
    imports: [
        CommonModule,
        TelXLSearchListComponent,
        TelXLButtonComponent,
        SearchPreviewComponent,
        InputSearchComponent,
    ],
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit {
    clickedItemId = signal<string>('');
    closeTemplateOverlay = output<boolean>();
    disabled = signal<boolean>(false);
    filteredItems = signal<ITemplate[]>([]);
    previousId = signal<string>('');
    showPreview = signal<ITemplate | undefined>(undefined);
    templateWithAction = output<{ template: ITemplate; action: string }>();
    templates$!: Observable<ITemplate[]>;
    mediaGroup = input.required<string>();

    constructor(private templateDataService: TemplateDataService) {}

    closeTemplates(): void {
        this.closeTemplateOverlay.emit(true);
        this.cleanUp();
    }

    handleButtonClick(event: { item: ITemplate; buttonType: string }): void {
        this.clickedItemId.set(event.item.id);

        if (event.buttonType === 'preview') {
            this.updatePreviewState(event.item);
        }

        this.emitTemplateWithAction(event.item, event.buttonType);
    }

    ngOnInit(): void {
        this.templates$ = this.templateDataService.get(this.mediaGroup());
        this.templateDataService
            .getTemplateLongContent()
            .pipe(
                tap(x => {
                    if (x && x.use === 'preview') {
                        this.showPreview.set(x.template);
                    }
                }),
            )
            .subscribe();
    }

    setFilteredItems(filteredItems: ITemplate[]): void {
        this.filteredItems.set(filteredItems);
    }

    private cleanUp(): void {
        this.clickedItemId.set('');
        this.showPreview.set(undefined);
        this.updatePreviousItemBy(this.previousId());
    }

    private emitTemplateWithAction(item: ITemplate, action: string): void {
        this.templateWithAction.emit({ template: item, action });
    }

    private updatePreviewState(newItem: ITemplate): void {
        const previousId = this.previousId();
        if (previousId !== '') {
            this.updatePreviousItemBy(previousId);
        }
        this.previousId.set(newItem.id);
        const currentItem = this.filteredItems().find(
            item => item.id === newItem.id,
        );
        if (currentItem) {
            currentItem.disabled = true;
        }
    }

    private updatePreviousItemBy(id: string): void {
        const previousItem = this.filteredItems().find(item => item.id === id);
        if (previousItem) {
            previousItem.disabled = false;
        }
    }
}
