import { Component, Input } from '@angular/core';

@Component({
  selector: 'chat-queue-name',
  templateUrl: './queue-name.component.html',
  standalone: true,
})
export class QueueNameComponent {
  @Input({required:true}) queueName!: string;
  @Input({required:true}) channelId!: string;
}