import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChatHelperService {
    updateBehaviorSubject<T>(subject: BehaviorSubject<T>, newValue: T) {
        const currentValue = subject.value;
        if (JSON.stringify(currentValue) !== JSON.stringify(newValue)) {
            subject.next(newValue);
        }
    }
}
