import { CommonModule } from '@angular/common';
import {
    Component,
    ContentChild,
    EventEmitter,
    OnInit,
    Output,
    Signal,
    TemplateRef,
    computed,
    input,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TelXLUserDataTextComponent } from '@dxp/elements';
import { ITemplate } from '@dxp/shared/models';
import { TruncatePipe } from '@dxp/shared/pipes';

@Component({
    selector: 'chat-search-list',
    standalone: true,
    imports: [CommonModule, FormsModule, TruncatePipe, TelXLUserDataTextComponent],
    templateUrl: './search-list.component.html',
    styleUrl: './search-list.component.scss',
})
export class TelXLSearchListComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ContentChild(TemplateRef) buttonTemplate!: TemplateRef<any>;
    displayItems!: Signal<ITemplate[]>;
    filteredItems = input<ITemplate[]>([]);
    @Output() itemEmitter = new EventEmitter<{
        item: ITemplate;
        buttonType: string;
    }>();

    listItemHeight = input(75);
    truncateItemLength = input(50);
    truncateTitleLength = input(45);
    visibleItems = input(3.5);

    get maxHeight(): string {
        return `${this.listItemHeight() * this.visibleItems()}px`;
    }

    ngOnInit(): void {
        this.displayItems = computed(() => {
            return this.filteredItems();
        });
    }

    onButtonClick(template: ITemplate, buttonType: string): void {
        const event = { item: template, buttonType };
        this.itemEmitter.emit(event);
    }
}
