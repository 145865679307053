/**
 * ChatHubIncomingService is an Angular service that provides observables for various chat events.
 * It uses ChatHubWrapperService to interact with the chat hub and logs debug information for each event.
 * 
 * The idea is to decouple the main chat hub SignalR feed and only expose incoming functions and properties.
 */

import { Injectable } from '@angular/core';
import {
    IAxisConversationID,
    IAxisMessage,
    IAxisSuggestedReply,
    IConversationEvent,
    IConversationInfoEvent,
    IErrorEvent,
    IParticipantTypingEvent
} from '@dxp/shared/models';
import { LoggerService } from '@dxp/shared/services';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { ChatHubWrapperService } from './chat-hub-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class ChatHubIncomingService {
    chatEnded$: Observable<IAxisConversationID>;
    chatHistory$: Observable<IAxisMessage[]>;
    chatMessage$: Observable<IAxisMessage>;
    conversationInfo$: Observable<IConversationInfoEvent>;
    error$: Observable<IErrorEvent>;
    participantTyping$: Observable<IParticipantTypingEvent>;
    suggestedReplies$: Observable<IAxisSuggestedReply[]>;
    refresh$: Observable<IConversationEvent>;

    constructor(
        private chatHubWrapperService: ChatHubWrapperService,
        private logger: LoggerService,
    ) {
        this.chatEnded$ = this.chatHubWrapperService.chatEnded$.pipe(
            tap(event => {
                this.logger.debug('ChatHubIncomingService: chatEnded$', event);
            }),
        );
        this.chatHistory$ = this.chatHubWrapperService.chatHistory$.pipe(
            tap(event => {
                this.logger.debug(
                    'ChatHubIncomingService: chatHistory$',
                    event,
                );
            }),
        );
        this.chatMessage$ = this.chatHubWrapperService.chatMessage$.pipe(
            tap(event => {
                this.logger.debug(
                    'ChatHubIncomingService: chatMessage$',
                    event,
                );
            }),
        );
        this.conversationInfo$ =
            this.chatHubWrapperService.conversationInfo$.pipe(
                tap(event => {
                    this.logger.debug(
                        'ChatHubIncomingService: conversationInfo$',
                        event,
                    );
                }),
            );
        this.error$ = this.chatHubWrapperService.error$.pipe(
            tap(event => {
                this.logger.debug('ChatHubIncomingService: error$', event);
            }),
        );
        this.participantTyping$ =
            this.chatHubWrapperService.participantTyping$.pipe(
                tap(event => {
                    this.logger.debug(
                        'ChatHubIncomingService: participantTyping$',
                        event,
                    );
                }),
            );
        this.suggestedReplies$ =
            this.chatHubWrapperService.suggestedReplies$.pipe(
                tap(event => {
                    this.logger.debug(
                        'ChatHubIncomingService: SuggestedReplies$',
                        event,
                    );
                }),
            );
        this.refresh$ = this.chatHubWrapperService.refresh$.pipe(
            tap(event => {
                this.logger.debug('ChatHubIncomingService: refresh$', event);
            }),
        );
    }
}
