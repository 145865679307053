import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HubConnectionState } from '@microsoft/signalr';
import { SignalRHubService } from '@signalr/signalr.hub.service';
import { environment } from '@core/environments/environment';
import { LoggerService } from '@services/logger/logger.service';
import { IAgentClientState } from '@models/agent/agent-state';

@Injectable({
    providedIn: 'root',
})
export class AgentHubService extends SignalRHubService {
    private agentStateSubject = new Subject<IAgentClientState>();
    agentState$ = this.agentStateSubject.asObservable();

    private _timer!: number;
    private _interval = environment.heartbeatInterval;

    constructor(
        logger: LoggerService,
        oidcSecurityService: OidcSecurityService,
    ) {
        super(
            'Agent',
            `${environment.blenderUrl}/agentHub`,
            oidcSecurityService,
            logger,
        );
    }

    protected override onStart() {
        this._timer = window.setInterval(
            () => this.heartbeat(),
            this._interval * 1000,
        );
    }

    protected override onStop() {
        window.clearInterval(this._timer);
    }

    protected override registerHandlers() {
        this.hubConnection.on('GetState', (agentState: IAgentClientState) => {
            this.messageReceived();

            this.logger.debug('Agent Service (GetState) ->');
            this.logger.table(agentState);

            this.agentStateSubject.next(agentState);
        });

        this.hubConnection.on(
            'StateTransitionRequested',
            (agentStateTransitionRequested: any) => {
                this.messageReceived();

                this.logger.debug(
                    'Agent Service (StateTransitionRequested) ->',
                );
                this.logger.table(agentStateTransitionRequested);
            },
        );

        this.hubConnection.on(
            'OnWorkItemClosed',
            (workItemClosedEvent: any) => {
                this.messageReceived();

                this.logger.debug('Agent Service (OnWorkItemClosed) ->');
                this.logger.table(workItemClosedEvent);
            },
        );
    }

    protected override unregisterHandlers() {
        this.hubConnection.off('GetState');
        this.hubConnection.off('StateTransitionRequested');
        this.hubConnection.off('OnWorkItemClosed');
    }

    heartbeat() {
        if (this.hubConnection.state === HubConnectionState.Connected) {
            this.logger.debug('Agent Service -> Heartbeat sent');
            this.hubConnection.send('HeartBeat');
        }
    }
}
