<label class="form-check-label" for="showActiveOnly">
    {{ displayText }} {{ isToggle }}
</label>
<input
    class="form-check-input w-100"
    type="checkbox"
    role="switch"
    id="showActiveOnly"
    [ngClass]="getSwitchClass()"
    (click)="toggleSwitch()"
/>
