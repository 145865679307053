import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDropdown, TelXLDropdownComponent } from '@dxp/elements';

import { CommonModule } from '@angular/common';
import { languageOptions } from '../models/translation';

@Component({
    selector: 'trans-drop-down',
    standalone: true,
    imports: [CommonModule, TelXLDropdownComponent],
    templateUrl: './drop-down.component.html',
    styleUrl: './drop-down.component.scss',
})
export class DropDownComponent {
    @Input() menuItems = languageOptions.map(lo => {
        const items: IDropdown<string> = {
            label: lo.label,
            value: lo.code,
            disabled: false,
        };

        return items;
    });
    @Input({ required: true }) selected!: number;
    @Output() selectedLanguage = new EventEmitter<string>();

    onSelectedChange(selected: string): void {
        this.selectedLanguage.emit(selected);
    }
}
