import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';

@Component({
    selector: 'chat-circle',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './circle.component.html',
    styleUrls: ['./circle.component.scss'],
})
export class CircleComponent {
    @Input() content = '';

    getBackgroundColor(): string {
        if (this.content === 'C') {
            return 'customer-background';
        } else if (this.content === 'A') {
            return 'agent-background';
        } else {
            return 'default-background';
        }
    }
}
