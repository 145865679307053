@if (!isConversationInformationLoading()) {
    <div class="container-fluid h-100 p-0">
        <div class="row">
            <div class="col">
                @if (!isChatItemLoading()) {
                    <telxl-panel
                        footerClass="p-0"
                        [theme]="mediaType()"
                        [scroll]="true"
                        [minHeight]="height()"
                        [maxHeight]="height()"
                    >
                        <ng-template #cardHeader>
                            <chat-header
                                [channelId]="chatItem().channelId"
                                [primaryChannelType]="
                                    chatItem().primaryChannelType
                                "
                                [queueName]="
                                    conversationInformation()?.conversation
                                        ?.queueName || 'Dummy Queue Name'
                                "
                                [mediaType]="mediaType()"
                                [transferDetails]="transferDetails()"
                                (selectedQueue)="onSelectedQueue($event)"
                            />
                        </ng-template>
                        <ng-template #cardBody>
                            <chat-body />
                        </ng-template>
                        <ng-template #cardFooter>
                            <chat-footer />
                        </ng-template>
                    </telxl-panel>
                }
            </div>
        </div>
    </div>
} @else {
    <div class="alert alert-warning d-flex align-items-center" role="alert">
        <i class="fas fa-exclamation-triangle fa-lg me-2"></i>
        <div>
            <strong>Warning:</strong>
            The requested chat item is not available. Please check the ID or try
            again later.
        </div>
    </div>
}
