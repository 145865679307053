import {
    EnvironmentType,
    IEnvironmentSettings,
} from '../models/configuration/environment';

import { LogLevel } from '../models/configuration/log';

export const createEnvironmentSettings = (baseSettings: {
    production: boolean;
    logLevel: LogLevel;
    authority: string;
    apiBaseUrl: string;
    odataBaseUrl: string;
    environment: EnvironmentType;
    scope: string;
    heartbeatInterval: number;
    pollingInterval: number;
    cacheLifeTime: number;
    isLoggingEnabled: boolean;
    isCacheEnabled: boolean;
}): IEnvironmentSettings => ({
    ...baseSettings,
    resize: {
        app: {
            standard: '180',
            expanded: '90',
        },
        dashboard: {
            standard: '190',
            expanded: '190',
        },
        performance: {
            standard: '360',
            expanded: '440',
        },
        chat: {
            standard: '190',
            expanded: '190',
        },
    },
    blenderUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/blender'
    }`,
    rbacUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/rbac'
    }`,
    voiceUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/telxl-voice'
    }`,
    liveUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/live'
    }`,
    aggregationsUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local
            ? ''
            : '/aggregations'
    }`,
    directoryUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/directory'
    }`,
    contentUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/content/v2'
    }`,
    translationUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/translate'
    }`,
    templatesUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local
            ? ''
            : '/rbac/templates'
    }`,
    templatesTenantsUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/templating-api/tenants'
    }`,
    workflowUrl: `${baseSettings.apiBaseUrl}${
        baseSettings.environment === EnvironmentType.Local ? '' : '/workflow'
    }`,
});
