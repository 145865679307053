<div class="text-start p-3">
    @if (event === 'Message') {
        @if (isToggle()) {
            {{ message.translatedText }}
        } @else {
            {{ message.textMessage }}

            @for (attachment of message.attachments; track $index) {
                <div>
                    <a [href]="attachment.contentUrl" download>
                        <i class="fas fa-download"></i>
                        {{ attachment.name }}
                    </a>
                </div>
            }
        }
    } @else {
        <chat-typing-indicator />
    }
</div>
