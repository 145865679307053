import { Injectable } from '@angular/core';
import { TemplatesApiService } from '@dxp/shared/api';
import { ITemplate } from '@dxp/shared/models';
import { LoggerService, TenantDataService } from '@dxp/shared/services';

import {
    BehaviorSubject,
    Observable,
    Subject,
    distinctUntilChanged,
    filter,
    of,
    switchMap,
    tap,
} from 'rxjs';

import { ChatHelperService } from '../chat-helper.service';

@Injectable({
    providedIn: 'root',
})
export class TemplateDataService {
    #addTemplateToInputForEdit = new BehaviorSubject<string>('');
    #setSelectedTemplate = new BehaviorSubject<ITemplate>({} as ITemplate);

    addTemplateToInputForEdit$ = this.#addTemplateToInputForEdit
        .asObservable()
        .pipe(
            filter(x => x.length > 0),
            tap(value => this.log('addTemplateForEdit$', value)),
        );
    templateSelectedGetLongContent$ = new Subject<{template: ITemplate, use: string}>();
    setSelectedTemplate$ = this.#setSelectedTemplate.asObservable().pipe(
        filter(searchItem => Object.keys(searchItem).length > 0),
        distinctUntilChanged(
            (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
    );

    constructor(
        private templateApiService: TemplatesApiService,
        private tenantService: TenantDataService,
        private logger: LoggerService,
        private chatHelperService: ChatHelperService,
    ) {}

    get(mediaGroup = 'Chat'): Observable<ITemplate[]> {
        return this.templateApiService.getTemplates(mediaGroup);
    }

    onTemplateSelected(template: ITemplate, use: string): void {
        this.log('onTemplateSelected', template);

        this.templateSelectedGetLongContent$.next({ template, use });
    }

    addTemplateForEdit(edit: string) {
        this.log('setAddTemplateForEdit', edit);
        this.chatHelperService.updateBehaviorSubject(
            this.#addTemplateToInputForEdit,
            edit,
        );
    }

    setSelectedTemplate(searchItem: ITemplate) {
        this.log('setSelectedTemplate', searchItem);
        this.chatHelperService.updateBehaviorSubject(
            this.#setSelectedTemplate,
            searchItem,
        );
    }

    getTemplateLongContent(): Observable<{template: ITemplate, use: string}> {
        this.log('tenantDescription', '');
        return this.tenantService.listen().pipe(
            switchMap(tenant =>
                this.templateSelectedGetLongContent$.pipe(
                    switchMap(c => {
                        return this.templateApiService.getLongContent(
                            c.template.id,
                            tenant.id,
                        ).pipe(
                            switchMap(content => {
                                const template = c.template;
                                template.item = content;
                                return of({template, use: c.use});
                            }),
                        );
                    }),
                ),
            ),
        );
    }

    private log(method: string, value: any) {
        this.logger.debug(`${method}:`, value);
    }
}
