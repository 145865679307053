import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, share, tap } from 'rxjs';
import { environment } from '@core/environments/environment';
import { LoggerService } from '@services/logger/logger.service';
import { IRbacTeam } from '@models/rbac/teams';
import {
    IKpiQueueThreshold,
    ITelXLKpiQueueThreshold,
} from '@models/rbac/queue-kpi';
import {
    IKpiTeamThreshold,
    ITelXLKpiTeamThreshold,
} from '@models/rbac/team-kpi';
import { IRbacUser } from '@models/rbac/user';
import { IRbacTenant, IRbacUserTenant } from '@models/rbac/tenant';
import { IRbacBusinessUnit } from '@models/rbac/business-unit';
import { IRbacRole } from '@models/rbac/permissions';
import { IRbacChannelProviderConfiguration } from '@models/rbac/channel-provider';
import { ChannelProviderType } from '@services/channel-provider/channel-provider.service';
import { IRbacRona, RonaType } from '@models/rbac/rona';
import { IWrap } from '@models/wrap/wrap';

interface IKpIQueueThresholdResult {
    thresholds: string;
    telXLThresholds: string;
}

@Injectable({
    providedIn: 'root',
})
export class RbacApiService {
    constructor(
        private logger: LoggerService,
        private http: HttpClient,
    ) {}

    getTeams(userId: string): Observable<IRbacTeam[]> {
        return this.http
            .get<IRbacTeam[]>(`${environment.rbacUrl}/teams/bymember/${userId}`)
            .pipe(
                tap({
                    next: teams => {
                        this.logger.debug(
                            'Rbac Service (Get Teams) -> Successful',
                            teams,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get Teams) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }

    getKpiThresholds(
        id: string,
        entity: 'teams' | 'channels' | 'queues' | 'businessunits' | 'tenants',
    ): Observable<{
        kpiThreshold: IKpiQueueThreshold | IKpiTeamThreshold;
        kpiTelXLThreshold: ITelXLKpiQueueThreshold | ITelXLKpiTeamThreshold;
    } | null> {
        return this.http
            .get<IKpIQueueThresholdResult>(
                `${environment.rbacUrl}/kpiThresholds/${entity}/${id}`,
            )
            .pipe(
                map(data => {
                    if (
                        entity === 'tenants' ||
                        entity === 'businessunits' ||
                        entity === 'queues'
                    ) {
                        return data !== null
                            ? {
                                  kpiThreshold: JSON.parse(
                                      data.thresholds,
                                  ) as IKpiQueueThreshold,
                                  kpiTelXLThreshold: JSON.parse(
                                      data.telXLThresholds,
                                  ) as ITelXLKpiQueueThreshold,
                              }
                            : null;
                    }

                    if (entity === 'teams') {
                        return data !== null
                            ? {
                                  kpiThreshold: JSON.parse(
                                      data.thresholds,
                                  ) as IKpiTeamThreshold,
                                  kpiTelXLThreshold: JSON.parse(
                                      data.telXLThresholds,
                                  ) as ITelXLKpiTeamThreshold,
                              }
                            : null;
                    }

                    return null;
                }),
                tap({
                    next: kpiThreshold => {
                        this.logger.debug(
                            'Rbac Service (Get KPI Thresholds) -> Successful',
                            kpiThreshold,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get KPI Thresholds) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }

    setKpiThresholds(
        id: string,
        entity: 'teams' | 'channels' | 'queues' | 'businessunits' | 'tenants',
        kpiThreshold?: IKpiQueueThreshold | IKpiTeamThreshold,
        kpiTelXLThreshold?: ITelXLKpiQueueThreshold | ITelXLKpiTeamThreshold,
    ) {
        const payload = {
            thresholds: kpiThreshold ? JSON.stringify(kpiThreshold) : null,
            telXLThresholds: kpiTelXLThreshold
                ? JSON.stringify(kpiTelXLThreshold)
                : null,
        };

        return this.http
            .post<void>(
                `${environment.rbacUrl}/kpiThresholds/${entity}/${id}`,
                payload,
            )
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Rbac Service (Set KPI Thresholds) -> Successful',
                            { id, kpiThreshold, kpiTelXLThreshold },
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Set KPI Thresholds) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }

    getUser(userId: string): Observable<IRbacUser> {
        return this.http
            .get<IRbacUser>(`${environment.rbacUrl}/users/${userId}`)
            .pipe(
                tap({
                    next: user => {
                        this.logger.debug(
                            'Rbac Service (Get User) -> Successful',
                            user,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get User) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }

    getConnectedUsers(userId: string): Observable<IRbacUserTenant[]> {
        return this.http
            .get<
                IRbacUserTenant[]
            >(`${environment.rbacUrl}/users/${userId}/connectedUsers`)
            .pipe(
                tap({
                    next: tenants => {
                        this.logger.debug(
                            'Rbac Service (Get Connected Users) -> Successful',
                            tenants,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get Connected Users) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }

    getTenant(tenantId: string): Observable<IRbacTenant> {
        return this.http
            .get<IRbacTenant>(`${environment.rbacUrl}/tenant/${tenantId}`)
            .pipe(
                tap({
                    next: tenants => {
                        this.logger.debug(
                            'Rbac Service (Get Tenant) -> Successful',
                            tenants,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get Tenant) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }

    getBusinessUnits(): Observable<IRbacBusinessUnit[]> {
        return this.http
            .get<IRbacBusinessUnit[]>(`${environment.rbacUrl}/businessUnits`)
            .pipe(
                tap({
                    next: businessUnits => {
                        this.logger.debug(
                            'Rbac Service (Get Business Unit) -> Successful',
                            businessUnits,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get Business Unit) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }

    getWrapDetails(): Observable<IWrap> {
        return this.http.get<IWrap>(environment.rbacUrl + `/wrapup`).pipe(
            tap({
                next: wrapDetails => {
                    this.logger.debug(
                        'Agent Service (Get wrap) -> Successful',
                        wrapDetails,
                    );
                },
                error: error => {
                    this.logger.error(
                        'Agent Service (Get wrap) -> Failed',
                        error,
                    );
                },
            }),
            share(),
        );
    }

    getPermissions(): Observable<IRbacRole> {
        return this.http.get<IRbacRole>(`${environment.rbacUrl}/me`).pipe(
            tap({
                next: role => {
                    this.logger.debug(
                        'Rbac Service (Get Permissions) -> Successful',
                        role,
                    );
                },
                error: error => {
                    this.logger.error(
                        'Rbac Service (Get Permissions) -> Failed',
                        error,
                    );
                },
            }),
            share(),
        );
    }

    getChannelProvidersConfiguration(
        channelProviderType: ChannelProviderType,
    ): Observable<IRbacChannelProviderConfiguration[]> {
        return this.http
            .get<
                IRbacChannelProviderConfiguration[]
            >(`${environment.rbacUrl}/channel-providers/${channelProviderType.toLocaleLowerCase()}/configurations`)
            .pipe(
                tap({
                    next: role => {
                        this.logger.debug(
                            'Rbac Service (Get Channel Provider Configuration) -> Successful',
                            role,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get Channel Provider Configuration) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }

    getRona(rona: RonaType): Observable<IRbacRona> {
        return this.http
            .get<IRbacRona>(`${environment.rbacUrl}/${rona}-rona`)
            .pipe(
                tap({
                    next: rona => {
                        this.logger.debug(
                            'Rbac Service (Get Rona) -> Successful',
                            rona,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Rbac Service (Get Rona) -> Failed',
                            error,
                        );
                    },
                }),
                share(),
            );
    }
}
