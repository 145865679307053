import { CommonModule } from '@angular/common';
import {
    Component,
    effect,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    runInInjectionContext,
    Signal,
    signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterModule } from '@angular/router';
import { MessageService } from '@dxp/chat';
import { TelXLTaskProgressComponent } from '@dxp/components';
import { AgentApiService, VoiceApiService } from '@dxp/shared/api';
import { secondsToDuration } from '@dxp/shared/helpers';
import {
    ICallState,
    IIncomingTask,
    IWorkItem,
    IWrap,
} from '@dxp/shared/models';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import {
    HeartbeatService,
    SettingsService,
    SipService,
    WorkItemService,
} from '@dxp/shared/services';
import { ChatHubService, EmailHubService } from '@dxp/shared/signalr';
import { Duration } from 'date-fns';
import { map } from 'rxjs';

@Component({
    selector: 'app-shell-task-belt-item-old',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        TelXLTaskProgressComponent,
        FormatDurationPipe,
    ],
    templateUrl: './task-belt-item-old.component.html',
    styleUrl: './task-belt-item-old.component.scss',
})
export class TaskBeltItemOldComponent implements OnInit {
    @Input() createdAtActual = new Date();
    @Input() isSelected = false;
    @Input() timeout = 0;
    @Input() task!: IIncomingTask;
    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

    stopProgress = false;
    displayTaskButtons = false;
    displayProgress = true;
    workItem!: Signal<IWorkItem>;
    inWrap = false;
    now = signal<Date>(new Date());

    wrapDetails!: IWrap;
    wrapDuration = 0;
    wrapResets = 0;
    wrapEnabled = false;

    constructor(
        private sipService: SipService,
        private router: Router,
        private settingsService: SettingsService,
        private voiceApiService: VoiceApiService,
        private chatHubService: ChatHubService,
        private emailHubService: EmailHubService,
        private workItemService: WorkItemService,
        private heartbeatService: HeartbeatService,
        private injector: Injector,
        private agentApiService: AgentApiService,
        private messageService: MessageService,
    ) {
        this.now = this.heartbeatService.lastHeartbeat;

        effect(() => {
            const workItem = this.workItem();
            if (workItem) {
                if (workItem.workItemState === 'Afterwork' && !this.inWrap) {
                    this.inWrap = true;
                }
            }
        });
    }

    ngOnInit() {
        this.displayTaskButtons =
            this.task.workItemState === 'Preview' &&
            this.timeout > 0 &&
            this.task.mediaType !== 'Voice';
        this.displayProgress = this.task.mediaType !== 'Voice';
        this.wrapDetails = this.settingsService.getWrapDetails();

        runInInjectionContext(this.injector, () => {
            const workItem$ = this.workItemService.workItems$.pipe(
                map(
                    wi =>
                        wi.find(
                            w => w.workItemId === this.task?.workItemId,
                        ) as IWorkItem,
                ),
            );
            this.workItem = toSignal(workItem$, {
                initialValue: {} as IWorkItem,
            });
        });
    }

    extendWrap() {
        this.agentApiService.resetWrapup(this.workItem().workItemId);
    }

    onClick() {
        if (!this.displayTaskButtons) {
            this.stopProgress = true;

            this.messageService.clearMessages();

            this.router.navigate([this.task.navigation, this.task.workItemId]);
        }
        this.isSelected = true;
        this.clicked.emit();
    }

    onAcceptClick(): void {
        if (this.task.mediaType === 'Voice') {
            this.sipService.answerCall();
            this.task.acceptedAt = new Date();
            const params: ICallState = {
                conversationId: this.task.conversationId,
                callState: 'Answer',
            };
            this.voiceApiService.changeCallState(params);
        } else {
            this.messageService.clearMessages();

            this.chatHubService.accept({
                conversationId: this.task.conversationId,
            });

            this.emailHubService.acceptEmail(this.task.conversationId);
        }

        this.isSelected = true;
        this.displayTaskButtons = false;
        this.router.navigate([this.task.navigation, this.task.workItemId]);
        this.timeout = 0;
    }

    onRejectClick(): void {
        if (this.task.mediaType === 'Voice') {
            const params: ICallState = {
                conversationId: this.task.conversationId,
                callState: 'Hangup',
            };
            this.voiceApiService.changeCallState(params);
            this.sipService.reject();
        } else {
            this.chatHubService.reject({
                conversationId: this.task.conversationId,
            });
            this.emailHubService.rejectEmail(this.task.conversationId);
        }
        this.displayProgress = false;
        this.displayTaskButtons = false;
    }

    get wrapCountdown(): Duration {
        if (this.wrapDetails.enabled) {
            return secondsToDuration(
                this.workItem().wrapupDurationSeconds -
                    Math.round(
                        (this.now().getTime() -
                            new Date(
                                this.workItem().lastStateChangeDate,
                            ).getTime()) /
                            1000,
                    ),
            );
        } else {
            return secondsToDuration(
                Math.round(
                    (new Date().getTime() -
                        new Date(
                            this.workItem().lastStateChangeDate,
                        ).getTime()) /
                        1000,
                ),
            );
        }
    }

    get wrapProgress(): number {
        const remainingTime =
            this.workItem().wrapupDurationSeconds -
            Math.round(
                (new Date().getTime() -
                    new Date(this.workItem().lastStateChangeDate).getTime()) /
                    1000,
            );
        return (remainingTime / this.wrapDetails.timeoutSeconds) * 100;
    }
}
