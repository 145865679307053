@if (displayItems().length === 0) {
    <div class="text-center py-4">
        <telxl-user-data-text />
    </div>
} @else {
    <ul class="list-group scroll-on-hover">
        @for (item of displayItems(); track item.id) {
            <li
                class="list-group-item d-flex flex-column"
                [ngClass]="{ disabled: item.disabled }"
            >
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-column flex-grow-1 w-100">
                        <div class="font-lg weight-medium">
                            {{ item.title | truncate: truncateTitleLength() }}
                        </div>
                        <span class="text-truncate">
                            {{ item.item | truncate: truncateItemLength() }}
                        </span>
                    </div>

                    <ng-container
                        *ngTemplateOutlet="
                            buttonTemplate;
                            context: {
                                $implicit: item,
                                clickHandler: onButtonClick.bind(this),
                            }
                        "
                    ></ng-container>
                </div>
            </li>
        }
    </ul>
}
