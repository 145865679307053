import { LogLevel } from './log';

export enum EnvironmentType {
    Local = 'Local',
    Dev = 'Dev',
    QA = 'QA',
    Sand = 'Sand',
    Production = 'Production',
}

export interface IEnvironmentSettings {
    production: boolean;
    logLevel: LogLevel;
    authority: string;
    apiBaseUrl: string;
    blenderUrl: string;
    rbacUrl: string;
    liveUrl: string;
    aggregationsUrl: string;
    directoryUrl: string;
    environment: EnvironmentType;
    heartbeatInterval: number;
    pollingInterval: number;
    cacheLifeTime: number;
    scope: string;
    resize: {
        dashboard: {
            standard: string;
            expanded: string;
        };
        performance: {
            standard: string;
            expanded: string;
        };
    };
}
