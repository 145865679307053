<div class="container">
    <div class="row">
        <!-- First Column in Row 1 -->
        <div class="col-6">
            <div class="">
                @if (templates$ | async; as items) {
                    <chat-input-search
                        [items]="items"
                        (filteredItems)="setFilteredItems($event)"
                    />
                }
            </div>
        </div>

        <!-- Second Column in Row 1 -->
        <div class="col-6">
            <div class="d-flex justify-content-end gap-2">
                <div class="w-0">
                    <telxl-button
                        colour="light"
                        class="w-25"
                        (buttonClick)="closeTemplates()"
                    >
                        <i class="fa-solid fa-xmark"></i>
                    </telxl-button>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <!-- First Column in Row 2 -->
        <div class="col-6">
            <div>
                <chat-search-list
                    [filteredItems]="filteredItems()"
                    (itemEmitter)="handleButtonClick($event)"
                >
                    <ng-template let-item let-clickFn="clickHandler">
                        <div class="d-flex justify-content-end gap-2">
                            <telxl-button
                                colour="light"
                                class="min-w-auto"
                                (buttonClick)="clickFn(item, 'preview')"
                                tabindex="0"
                                (keydown)="clickFn(item, 'preview')"
                            >
                                Preview
                            </telxl-button>

                            <telxl-button
                                colour="primary"
                                class="min-w-auto"
                                [outline]="true"
                                (buttonClick)="clickFn(item, 'use')"
                                tabindex="0"
                                (keydown)="clickFn(item, 'use')"
                            >
                                Use
                            </telxl-button>
                        </div>
                    </ng-template>
                </chat-search-list>
            </div>
        </div>

        <!-- Second Column in Row 2 -->
        <div class="col-6">
            <div class="border rounded h-100">
                @if (showPreview()) {
                    <chat-search-preview
                        [searchPreview]="showPreview()"
                        (searchPreviewEmitter)="handleButtonClick($event)"
                    />
                }
            </div>
        </div>
    </div>
</div>
