@if (latestMessages$ | async; as messages) {
    @for (message of messages | sort: 'createdAt' : 'asc'; track message.id) {
        <chat-shell-message event="Message" [message]="message" title="Chat message" aria-label="Chat message" />
    }
    @if (isCustomerTyping()) {
        <chat-shell-message event="Typing" [message]="typing()" title="Customer is typing" aria-label="Customer is typing" />
    }
} @else {
    <div class="h-100" title="No messages to display" aria-live="polite">There are no messages to display...</div>
}
