import { Component, Input } from '@angular/core';
import { IMessage, ISender } from '@dxp/shared/models';

import { ChatCardComponent } from '../../card/card.component';
import { CircleComponent } from '../circle/circle.component';
import { CommonModule } from '@angular/common';
import { DisplayMessageComponent } from '../display/display-message-content.component';
import { OuterMessageDetailsComponent } from '../../message/outer-message-details/outer-message-details.component';
import { TypingIndicatorComponent } from '../typing/typing.component';

@Component({
    selector: 'chat-agent-message',
    templateUrl: './agent.component.html',
    styleUrls: ['./agent.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TypingIndicatorComponent,
        CircleComponent,
        ChatCardComponent,
        DisplayMessageComponent,
        OuterMessageDetailsComponent,
    ],
})
export class AgentComponent {
    @Input({ required: true }) event!: 'Message' | 'Typing';
    @Input() message!: IMessage;
    @Input() sender!: ISender;
}
