<div class="d-flex gap-2">
    <telxl-button
        colour="primary"
        [outline]="true"
        [size]="'sm'"
        (buttonClick)="useMessage()"
        (keydown.enter)="useMessage()"
        tabindex="0"
        aria-label=""
        [disabled]="isButtonDisabled()"
    >
        <div class="d-flex align-items-center">
            <span>Use</span>
        </div>
    </telxl-button>
</div>
