<div
    class="d-flex flex-column align-items-center justify-content-center bg-light"
>
    <div class="text-center">
        <!-- Optional Icon -->
        <i class="bi bi-exclamation-circle-fill text-danger display-4 mb-3"></i>

        <!-- Heading -->
        <h1 class="display-4 fw-bold text-secondary mb-3">404</h1>

        <!-- Message -->
        <p class="lead text-muted mb-4">
            The item you were looking for was not found.
        </p>

        <!-- Optional Button to Go Back -->
        <a href="/" class="btn btn-primary btn-lg">
            <i class="bi bi-arrow-left me-2"></i>
            Go to the dashboard page
        </a>
    </div>
</div>
