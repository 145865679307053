import {
    Component,
    computed,
    effect,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from '@api/auth/auth.service';
import { NotificationHubService } from '@signalr/notification/notification.hub.service';
import { SideNavComponent } from '@base/components/side-nav/container/side-nav.component';
import { MainLayoutComponent } from '@base/components/main-layout/main-layout.component';
import { HeaderComponent } from '@base/components/header/header.component';
import { NotificationModalComponent } from '@components/notification-modal/notification-modal.component';
import { NotificationToastComponent } from '@components/notification-toast/notification-toast.component';
import { UserService } from '@services/user/user.service';
import { QueueService } from '@services/queue/queue.service';
import { SipService } from '@services/sip/sip.service';
import { IUser } from '@models/auth/user';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        SideNavComponent,
        MainLayoutComponent,
        HeaderComponent,
        NotificationToastComponent,
    ],
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild('localAudio') localAudio!: ElementRef<HTMLAudioElement>;
    @ViewChild('remoteAudio') remoteAudio!: ElementRef<HTMLAudioElement>;

    private _notificationSubscription!: Subscription;
    isCollapsed = false;
    showModal = false;
    notification = this.notificationHubService.notificationAlert;

    loading = computed<boolean>(() => {
        return (
            this.queueService.queues().length === 0 ||
            this.queueService.queues().some(q => q.information === undefined)
        );
    });

    constructor(
        private modalService: NgbModal,
        private authService: AuthService,
        private userService: UserService,
        private notificationHubService: NotificationHubService,
        private queueService: QueueService,
        private sipService: SipService,
    ) {
        effect(() => {
            if (this.localAudio && this.sipService.localStream()) {
                this.localAudio.nativeElement.srcObject =
                    this.sipService.localStream();
            }
        });
    }

    async ngOnInit(): Promise<void> {
        this.authService.initializeAuthentication();

        // Hard coded until we have the ability to retrieve properly
        /*const servers = [{ uri: 'qa-testast-1.call-view.com' }];

        const stunServers = [
            'stun:165.227.235.48:3478',
            'stun:138.68.149.118:3478',
            'stun:172.187.249.206:3478',
        ];

        this.sipService.initializeSipClients(
            servers,
            stunServers,
            'webrtc_13',
            '8254666664',
        );
        //To here
        */
        this.authService.initializeAuthentication();

        this._notificationSubscription =
            this.notificationHubService.notificationAlert.subscribe(
                notification => {
                    if (notification) {
                        const modalRef = this.modalService.open(
                            NotificationModalComponent,
                            {
                                centered: true,
                                keyboard: false,
                                backdrop: 'static',
                                windowClass: 'modal-wrap',
                            },
                        );

                        modalRef.componentInstance.notification = notification;
                        modalRef.componentInstance.show();
                    } else {
                        this.modalService.dismissAll();
                    }
                },
            );
    }

    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    sideNavMargin(): string[] {
        return this.isCollapsed ? ['m-2'] : ['ms-3', 'mt-2', 'mb-2', 'me-3'];
    }

    ngOnDestroy() {
        if (this._notificationSubscription) {
            this._notificationSubscription.unsubscribe();
        }
    }

    get user(): IUser {
        return this.userService.user();
    }
}
