import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';

import { ConversationService } from '../../services/conversation.service';

@Component({
    selector: 'chat-customer-name',
    templateUrl: './customer-name.component.html',
    styleUrls: ['./customer-name.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class CustomerNameComponent implements OnInit {
    customerName$!: Observable<string | undefined>;

    constructor(private conversationService: ConversationService) {}

    ngOnInit(): void {
        this.customerName$ =
            this.conversationService.conversationInformation$.pipe(
                map(ci => ci.info.customerName),
            );
    }
}
