import { CommonModule } from '@angular/common';
import { Component, OnInit, Signal, computed } from '@angular/core';
import { SortPipe } from '@dxp/shared/pipes';
import { Observable, merge } from 'rxjs';

import { MessageComponent } from '../../../../../message/message.component';
import { MessageService } from './../../../../../services/message.service';
import { NotificationService } from './../../../../../services/notification.service';
import { IAxisMessage } from '@dxp/shared/models';

@Component({
    selector: 'chat-body',
    standalone: true,
    imports: [CommonModule, SortPipe, MessageComponent],
    templateUrl: './body.component.html',
    styleUrl: './body.component.scss',
})
export class BodyComponent implements OnInit {
    isCustomerTyping!: Signal<boolean>;
    latestMessages$: Observable<IAxisMessage[]>;
    typing = computed(() => {
        this.notificationService.isCustomerTyping();
        return this.notificationService.typing();
    });

    constructor(
        private notificationService: NotificationService,
        private messageService: MessageService,
    ) {
        this.latestMessages$ = merge(
            this.messageService.messages$,
            this.messageService.translatedMessages$,
        );
    }

    ngOnInit(): void {
        this.isCustomerTyping = this.notificationService.isCustomerTyping;
    }
}
