import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'app-shell-not-found',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './item-not-found.component.html',
    styleUrl: './item-not-found.component.scss',
})
export class NotFoundComponent {}
