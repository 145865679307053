import { Injectable } from '@angular/core';
import { IAxisSuggestedReply } from '@dxp/shared/models';
import {
    BehaviorSubject,
    Observable,
    distinctUntilChanged,
    filter,
    tap,
} from 'rxjs';

import { ChatHubIncomingService } from '../hub/chat-hub-incoming.service';

@Injectable({
    providedIn: 'root',
})
/**
 * This service manages SuggestedReply data and exposes it as observables.
 * It should only expose observables and not the underlying BehaviorSubject.
 * It has a startFeed method which is called to start listening for data coming in.
 * This method also has a side effect that stores the data so we can react to incoming data
 * without exposing hub related service methods.
 */
export class SuggestedRepliesDataService {
    #suggestedReply = new BehaviorSubject<IAxisSuggestedReply>(
        {} as IAxisSuggestedReply,
    );
    SuggestedReplies = new BehaviorSubject<IAxisSuggestedReply[]>([]);
    #isShowSuggestedReplies = new BehaviorSubject<number>(0);

    constructor(private chatHubIncomingService: ChatHubIncomingService) {}

    listen(): Observable<IAxisSuggestedReply[]> {
        return this.SuggestedReplies.asObservable();
    }

    listenForSelectedSuggestedReplies(): Observable<IAxisSuggestedReply> {
        return this.#suggestedReply
            .asObservable()
            .pipe(
                filter(
                    suggestedReply => Object.keys(suggestedReply).length > 0,
                ),
            );
    }

    listenForIsShowSuggestedReplies(): Observable<number> {
        return this.#isShowSuggestedReplies.asObservable();
    }

    setIsShowSuggestedReplies(isShowSuggestedReplies: number): void {
        this.#isShowSuggestedReplies.next(isShowSuggestedReplies);
    }

    setSuggestedReply(suggestedReply: IAxisSuggestedReply): void {
        this.#suggestedReply.next(suggestedReply);
    }

    startFeed(): Observable<IAxisSuggestedReply[]> {
        return this.chatHubIncomingService.suggestedReplies$.pipe(
            filter(suggestedReplies =>
                suggestedReplies.some(r => r.text !== 'No answer found'),
            ),
            distinctUntilChanged(
                (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
            ),
            tap(suggestedReplies => {
                this.SuggestedReplies.next(suggestedReplies);

                this.#isShowSuggestedReplies.next(1);
            }),
        );
    }
}
