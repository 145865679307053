import { Injectable, signal } from '@angular/core';
import { ChannelGroupType } from '@models/queue/queue';
import { RonaType } from '@models/rbac/rona';
import { IIncomingTask } from '@models/task/task';
import { IWrap } from '@models/wrap/wrap';
import { ViewType } from '@helpers/style';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private _ronaConfiguration: Map<RonaType, number> = new Map();
    private _wrapDetails!: IWrap;

    incomingVoiceTask = signal<IIncomingTask | undefined>(undefined);
    incomingWebchatTask = signal<IIncomingTask | undefined>(undefined);

    viewType = signal<ViewType>('standard');
    channelGroupFilter = signal<ChannelGroupType | undefined>(undefined);

    setRona(rona: RonaType, timeOut: number) {
        this._ronaConfiguration.set(rona, timeOut);
    }

    clearChannelProviderConfigurations() {
        this._ronaConfiguration.clear();
    }

    getRona(rona: RonaType): number {
        return this._ronaConfiguration.get(rona) ?? 0;
    }

    setIncomingVoiceTask(task: IIncomingTask): void {
        this.incomingVoiceTask.set(task);
    }

    setIncomingWebchatTask(task: IIncomingTask): void {
        this.incomingWebchatTask.set(task);
    }

    clearIncomingWebchatTask(): void {
        this.incomingWebchatTask.set(undefined);
    }

    setWrapDetails(wrap: IWrap) {
        this._wrapDetails = wrap;
    }

    getWrapDetails(): IWrap {
        return this._wrapDetails;
    }
}
