import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { IAxisSuggestedReply } from '@dxp/shared/models';
import { ErrorHandlerService } from '@dxp/shared/services';

import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { TextAreaComponent } from './components/text-area/text-area.component';

@Component({
    selector: 'chat-suggested-replies',
    standalone: true,
    imports: [CommonModule, TextAreaComponent, ActionButtonsComponent],
    templateUrl: './suggested-replies.component.html',
    styleUrl: './suggested-replies.component.scss',
})
export class SuggestedRepliesComponent {
    message = {} as IAxisSuggestedReply;
    reply = output<IAxisSuggestedReply>();
    suggestedReplies = input.required<IAxisSuggestedReply[]>();

    constructor(private errorHandlerService: ErrorHandlerService) {}

    sendMessage(id: string) {
        if (id.length === 0)
            this.errorHandlerService.handleError('ID is required');

        const reply =
            this.suggestedReplies().find(r => r.messageId === id) ??
            ({} as IAxisSuggestedReply);
        if (Object.keys(reply).length === 0)
            this.errorHandlerService.handleError('Suggested Reply not found');

        this.reply.emit(reply);
    }

    updateMessage(newMessage: IAxisSuggestedReply) {
        this.message = newMessage;
    }
}
