<div class="w-10">
    Language: {{ language }}
    <trans-toggle
        [displayText]="'My Dropdown'"
        (toggleOutput)="setIsTranslate($event)"
    />

    @if (isTranslate) {
        <trans-drop-down
            [selected]="0"
            (selectedLanguage)="setSelectedLanguage($event)"
        />
    }
</div>
