<div class="container-fluid">
    @if (
        state() === 'Authenticating' ||
        state() === 'Loading' ||
        state() === 'GoLive'
    ) {
        <div class="row">
            <div class="col-12">
                <div
                    class="full-container"
                    [style.max-height]="calculateFullHeight"
                    [style.height]="calculateFullHeight"
                >
                    <app-shell-main-layout-initialisation [state]="state()" />
                    @if (allowStatus) {
                        <div class="status">
                            <div>
                                <span class="me-1">Authenticated:</span>
                                <span>
                                    {{ isAuthenticated() ? 'Yes' : 'No' }}
                                </span>
                            </div>
                            <div>
                                <span class="me-1">Presence:</span>
                                <span>{{ presence().label }}</span>
                            </div>
                            <div>
                                <span class="me-1">Tenant:</span>
                                <span>
                                    {{ ready().tenant ? 'Ready' : 'Waiting' }}
                                </span>
                            </div>
                            <div>
                                <span class="me-1">Business Units:</span>
                                <span>
                                    {{ ready().business ? 'Ready' : 'Waiting' }}
                                </span>
                            </div>
                            <div>
                                <span class="me-1">Queues:</span>
                                <span>
                                    {{ ready().queue ? 'Ready' : 'Waiting' }}
                                </span>
                            </div>
                            <div>
                                <span class="me-1">Agents:</span>
                                <span>
                                    {{ ready().agent ? 'Ready' : 'Waiting' }}
                                </span>
                            </div>
                            <div>
                                <span class="me-1">Teams:</span>
                                <span>
                                    {{ ready().team ? 'Ready' : 'Waiting' }}
                                </span>
                            </div>
                            <div>
                                <span class="me-1">User:</span>
                                <span>
                                    {{ ready().user ? 'Ready' : 'Waiting' }}
                                </span>
                            </div>
                            <div>
                                <span class="me-1">Workitems:</span>
                                <span>
                                    {{ ready().workitem ? 'Ready' : 'Waiting' }}
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    } @else if (state() === 'Break') {
        <div class="row">
            <div class="col-12">
                <div
                    class="full-container"
                    [style.max-height]="calculateFullHeight"
                    [style.height]="calculateFullHeight"
                >
                    <app-shell-main-layout-break [presence]="presence()" />
                </div>
            </div>
        </div>
    } @else if (state() === 'Active') {
        <div class="row">
            <div class="d-none col-12 ps-2 pe-3">
                <div class="row mb-2 mt-2">
                    <app-shell-task-belt-old />
                </div>
            </div>
            <div class="col-12 mt-2 mb-2">
                <app-shell-task-belt />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div
                    class="main-container"
                    [style.max-height]="calculateHeight"
                    [style.height]="calculateHeight"
                >
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    }
</div>
