<div class="container-fluid">
    <div class="fade-transition" [ngClass]="{ active: true }">
        <div class="row">
            <div class="col-12">
                <div class="row mb-2 mt-2">
                    <task-belt></task-belt>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="main-container">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
