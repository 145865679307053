import { CommonModule } from '@angular/common';
import { Component, output, signal } from '@angular/core';
import { TelXLButtonComponent } from '@dxp/elements';

@Component({
    selector: 'chat-action-buttons',
    standalone: true,
    imports: [CommonModule, TelXLButtonComponent],
    templateUrl: './action-buttons.component.html',
    styleUrl: './action-buttons.component.scss',
})
export class ActionButtonsComponent {
    send = output<void>();
    isButtonDisabled = signal<boolean>(false);

    useMessage() {
        this.isButtonDisabled.set(!this.isButtonDisabled());
        this.send.emit();
    }
}
