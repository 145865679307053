export interface ICostCentres {
    sessionId: string;
    isSuccess: boolean;
    data: ICostCentre[];
}

export interface ICostCentre {
    ID: number;
    title: string;
}
