import { Injectable } from '@angular/core';
import { differenceInSeconds } from 'date-fns';
import { environment } from '../../environments/environment';
import { LoggerService } from '../../services/logger/logger.service';

interface ICache<T> {
    data: T;
    timeStamp: Date;
}

export interface CacheHandler<T> {
    cacheKey: string;
    cacheType: string;
    setter: (data: T, fromCache: boolean) => void;
}

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    constructor(private loggerService: LoggerService) {}

    getFromCache<T>(key: string): T | null {
        if (!environment.isCacheEnabled) return null;

        const cacheString = sessionStorage.getItem(key);

        if (cacheString) {
            const cache = JSON.parse(cacheString) as ICache<T>;
            if (
                differenceInSeconds(new Date(), cache.timeStamp) <
                environment.cacheLifeTime
            ) {
                this.loggerService.debug(`Loading cache for key ${key}`, cache);
                return cache.data;
            } else {
                this.loggerService.debug(`Cache expired for key ${key}`);
                sessionStorage.removeItem(key);
            }
        }
        return null;
    }

    saveToCache<T>(key: string, data: T): void {
        if (environment.isCacheEnabled) {
            const cache: ICache<T> = {
                data,
                timeStamp: new Date(),
            };
            sessionStorage.setItem(key, JSON.stringify(cache));
        }
    }

    handleCache<T>(
        cacheType: string,
        cacheKey: string,
        cacheId: string,
        setter: (data: T, fromCache: boolean) => void,
    ) {
        const cache = this.getFromCache<T>(
            `${cacheType}-${cacheKey}-${cacheId}`,
        );
        if (cache) {
            setter(cache, true);
        }
    }

    removeCache(key: string): void {
        sessionStorage.removeItem(key);
    }

    clearCache(): void {
        sessionStorage.clear();
    }
}
