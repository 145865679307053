<div
    class="progress-bar progress-task ms-2"
    [ngClass]="{
        'bg-success': progress > 66,
        'bg-warning': progress > 33 && progress <= 66,
        'bg-danger': progress <= 33,
    }"
    role="progressbar"
    [style.width]="progress + '%'"
></div>
