import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    input,
    output,
} from '@angular/core';
import { IAxisTransferDetails, ITransferQueue } from '@dxp/shared/models';

import { ChannelNameComponent } from '../../../../components/channel-name/channel-name.component';
import { CustomerNameComponent } from '../../../../components/customer-name/customer-name.component';
import { HeaderButtonsComponent } from '../../../../components/header-buttons/header-buttons.component';
import { QueueNameComponent } from '../../../../components/queue-name/queue-name.component';
import { TopRightComponent } from '../../../../components/top-right/top-right.component';

@Component({
    selector: 'chat-header',
    standalone: true,
    imports: [
        CommonModule,
        CustomerNameComponent,
        TopRightComponent,
        ChannelNameComponent,
        QueueNameComponent,
        HeaderButtonsComponent,
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class TopLayoutComponent {
    @Input({ required: true }) channelId!: string;
    @Output() closeModalEmitter = new EventEmitter<boolean>();
    mediaType = input.required<string>();
    @Output() openModalEmitter = new EventEmitter<boolean>();
    @Input({ required: true }) primaryChannelType!: string;
    @Input({ required: true }) queueName!: string;
    selectedQueue = output<ITransferQueue>();
    transferDetails = input.required<IAxisTransferDetails>();

    closeModal() {
        this.closeModalEmitter.emit(true);
    }

    openModal() {
        this.openModalEmitter.emit(true);
    }

    onSelectedQueue(queue: ITransferQueue) {
        this.selectedQueue.emit(queue);
    }
}
