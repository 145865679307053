import { Component, Input, Signal } from '@angular/core';
import { IMessage, ISender } from '@dxp/shared/models';

import { TranslationService } from '@dxp/translations';
import { TypingIndicatorComponent } from '../typing/typing.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'chat-display-message-content',
    standalone: true,
    imports: [CommonModule, TypingIndicatorComponent],
    templateUrl: './display-message-content.component.html',
    styleUrls: ['./display-message-content.component.scss'],
})
export class DisplayMessageComponent {
    @Input({ required: true }) event!: 'Message' | 'Typing';
    @Input() message!: IMessage;
    @Input() sender!: ISender;
    isToggle!: Signal<boolean>;

    constructor(private translationService: TranslationService) {
        this.isToggle = toSignal(this.translationService.isToggle$, {
            initialValue: false,
        });
    }
}
