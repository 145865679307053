@if (displayedTasks().length > 6) {
    <div class="taskbelt-badge" #taskBelt>
        <img src="assets/images/badge.png" class="img-fluid w-100" />
        <span class="badge-text">+{{ displayedTasks().length - 6 }}</span>
    </div>
}
<div
    class="col-12 task-belt pe-3 m-1"
    [ngClass]="delayedTasksExist ? 'task-belt-items' : 'task-belt-waiting'"
    #taskbelt
>
    @if (!delayedTasksExist) {
        <div class="h-100">
            <div class="d-flex align-items-center h-100">
                <div class="align-self-start mt-2 ms-2 font-small">
                    Current Status
                </div>
                <div class="ms-4">
                    <i class="fa-light fa-2x fa-circle-check"></i>
                </div>
                <div class="ms-4 me-1 fs-3">Available</div>
                <div class="ms-5 fs-2">
                    {{ stateSince | formatDuration }}
                </div>
            </div>
        </div>
    }
    <div class="row h-100">
        @for (task of displayedTasks() | slice: 0 : 6; track task.workItemId) {
            <div
                class="col-2 mb-2"
                [ngClass]="{ 'pe-0': $index !== 5 }"
                [@dropInOut]="{
                    value: '',
                    params: {
                        transformStart:
                            task.mediaType === 'Voice'
                                ? 'translateX(-25%)'
                                : 'translateY(-20px)',
                    },
                }"
            >
                <task-belt-item
                    (clicked)="deselectOthers(task.workItemId)"
                    [isSelected]="isSelected(task.workItemId)"
                    [timeout]="selectTimeout(task.mediaType)"
                    [task]="task"
                ></task-belt-item>
            </div>
        }
    </div>
</div>
