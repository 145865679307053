import { Component, Input } from '@angular/core';
import { IMessage, ISender } from '@dxp/shared/models';

import { CommonModule } from '@angular/common';
import { AgentComponent } from '../components/agent/agent.component';
import { CustomerComponent } from '../components/customer/customer.component';

@Component({
    selector: 'chat-shell-message',
    standalone: true,
    imports: [
        CommonModule,
        AgentComponent,
        CustomerComponent,
    ],
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
    @Input() event!: 'Message' | 'Typing';
    @Input() message!: IMessage;

    getSender(sender: ISender): string {
        switch (sender.role) {
            case 'Bot':
                return 'Bot';
            case 'Customer':
                return sender.name && sender.name.length > 0
                    ? sender.name
                    : 'Unknown Customer';
            default:
                return sender.name && sender.name.length > 0
                    ? sender.name
                    : sender.role;
        }
    }
}
