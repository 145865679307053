import { Injectable } from '@angular/core';
import { LoggerService } from '@dxp/shared/services';
import { BehaviorSubject, filter } from 'rxjs';

import { ChatHelperService } from './chat-helper.service';

@Injectable({
    providedIn: 'root',
})
export class ChannelService {
    private _ChannelID = new BehaviorSubject<number>(0);

    channelID$ = this._ChannelID.asObservable().pipe(
        filter(ci => !!ci),
        filter(ci => ci > 0),
    );

    constructor(
        private logger: LoggerService,
        private chatGeneralService: ChatHelperService,
    ) {}

    setChannel(ID: number) {
        if (!ID) {
            this.logger.error('Channel ID is not defined');
            throw new Error('Channel ID is not defined');
        }
        this.chatGeneralService.updateBehaviorSubject(this._ChannelID, ID);
    }
}
