import { Injectable } from '@angular/core';
import { IAxisMessage, IConversationEvent } from '@dxp/shared/models';
import { LoggerService } from '@dxp/shared/services';

import { BehaviorSubject, Observable, filter, map, tap } from 'rxjs';

import { ChatHelperService } from './chat-helper.service';
import { ChatHubIncomingService } from './hub/chat-hub-incoming.service';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    #messages = new BehaviorSubject<IAxisMessage[] | undefined>(undefined);
    #translatedMessages = new BehaviorSubject<IAxisMessage[] | undefined>(
        undefined,
    );

    messages$ = this.#messages.asObservable().pipe(
        filter(ci => !!ci),
        tap(value => this.log('messages$', value)),
    );
    translatedMessages$ = this.#translatedMessages.asObservable().pipe(
        filter(ci => !!ci),
        tap(value => this.log('translatedMessages$', value)),
    );

    constructor(
        private logger: LoggerService,
        private chatGeneralService: ChatHelperService,
        private chatHubIncomingService: ChatHubIncomingService,
    ) {}

    clearMessages() {
        this.chatGeneralService.updateBehaviorSubject(this.#messages, []);
    }

    listenForChatHistory(conversationId: string): Observable<IAxisMessage[]> {
        return this.chatHubIncomingService.chatHistory$.pipe(
            map(messages =>
                messages.filter(m => m.conversationId === conversationId),
            ),
        );
    }

    listenForChatMessage(conversationId: string): Observable<IAxisMessage> {
        return this.chatHubIncomingService.chatMessage$.pipe(
            filter(message => message.conversationId === conversationId),
        );
    }

    refreshWith(primaryConversationId: string): Observable<IConversationEvent> {
        return this.chatHubIncomingService.refresh$.pipe(
            filter(
                (refresh: IConversationEvent) =>
                    refresh.conversationId === primaryConversationId,
            ),
        );
    }
    
    setMessages(messages: IAxisMessage[]) {
        this.log('setMessages', messages);
        if (!messages || messages.length === 0) {
            this.logger.error('Messages are not defined');
            throw new Error('Messages are not defined');
        }

        const currentMessages = this.#messages.value ?? [];
        const uniqueMessages = messages.filter(
            (message, index, self) =>
                index === self.findIndex(m => m.id === message.id),
        );


        const mergedMessages = [...currentMessages, ...uniqueMessages].filter(
            (message, index, self) =>
                index === self.findIndex(m => m.id === message.id),
        );

        this.chatGeneralService.updateBehaviorSubject(
            this.#messages,
            mergedMessages,
        );
    }

    setTranslatedMessages(messages: IAxisMessage[]) {
        this.log('setTranslatedMessages', messages);
        if (!messages || messages.length === 0) {
            this.logger.error('Translated Messages are not defined');
            throw new Error('Translated Messages are not defined');
        }
        this.chatGeneralService.updateBehaviorSubject(
            this.#translatedMessages,
            messages,
        );
    }

    private log(method: string, value: unknown) {
        this.logger.debug(`${method}:`, value);
    }
}
