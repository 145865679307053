import { Injectable } from '@angular/core';
import { PresenceApiService } from '@dxp/shared/api';
import {
    AgentServiceMG,
    BusinessUnitDataService,
    ProvidersConfiguratationServiceMG,
    QueueServiceMG,
    RonaServiceMG,
    TeamDataService,
    TenantServiceMG,
    UserDataService,
    UserWorkitemService,
    WrapServiceMG,
} from '@dxp/shared/services';

import { Subscription, combineLatest, forkJoin, switchMap, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SystemService {
    initialiseSystemSubscription!: Subscription;

    constructor(
        private agentsServiceMG: AgentServiceMG,
        private businessUnitDataService: BusinessUnitDataService,
        private presenceApiService: PresenceApiService,
        private providersConfiguratationServiceMG: ProvidersConfiguratationServiceMG,
        private queueServiceMG: QueueServiceMG,
        private ronaServiceMG: RonaServiceMG,
        private teamsDataService: TeamDataService,
        private tenantServiceMG: TenantServiceMG,
        private userDataService: UserDataService,
        private userWorkitemService: UserWorkitemService,
        private wrapServiceMG: WrapServiceMG,
    ) {}

    initialise() {
        const teams$ = this.userDataService
            .listenAxisUser()
            .pipe(
                switchMap(axisUser => this.teamsDataService.getTeams(axisUser)),
            );
        const queues$ = this.teamsDataService
            .listen()
            .pipe(switchMap(ts => this.queueServiceMG.getQueues(ts)));
        const agents$ = this.teamsDataService
            .listenForMemberIds()
            .pipe(switchMap(mi => this.agentsServiceMG.getAgents(mi)));
        const businessUnits$ = this.businessUnitDataService.getBusinessUnits();
        businessUnits$.subscribe();
        const rona$ = this.ronaServiceMG.getRona;
        const tenant$ = this.userDataService
            .listenAxisUser()
            .pipe(
                switchMap(axisUser => this.tenantServiceMG.getTenant(axisUser)),
            );
        const providersConfigurations$ = forkJoin(
            this.providersConfiguratationServiceMG.getProviderConfigurations,
        );
        const wrap$ = this.wrapServiceMG.getWrap;

        this.initialiseSystemSubscription = this.userDataService.getUser
            .pipe(
                tap(axisUser => {
                    this.userDataService.setAxisUser(axisUser);
                }),
                switchMap(() => this.presenceApiService.login()),
                switchMap(() => teams$),
                switchMap(() =>
                    combineLatest([
                        queues$,
                        agents$,
                        businessUnits$,
                        rona$,
                        tenant$,
                        providersConfigurations$,
                        wrap$,
                        this.userWorkitemService.userWorkItems,
                    ]),
                ),
            )
            .subscribe();
    }
}
