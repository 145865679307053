import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { IMessage } from '@dxp/shared/models';

@Component({
    selector: 'chat-outer-message-details',
    templateUrl: './outer-message-details.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class OuterMessageDetailsComponent {
    @Input({required: true}) displayMessageFor!: 'customer' | 'agent';
    @Input({ required: true }) message!: IMessage;
    @Input({ required: true }) messageFrom = '';
    @Input({ required: true }) name = 'Unknown';
}
