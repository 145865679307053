import { CommonModule } from '@angular/common';
import { Component, OnInit, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ITemplate } from '@dxp/shared/models';

@Component({
    selector: 'chat-input-search',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './input-search.component.html',
    styleUrl: './input-search.component.scss',
})
export class InputSearchComponent implements OnInit {
    filteredItems = output<ITemplate[]>();
    items = input.required<ITemplate[]>();
    searchTerm = signal('');

    ngOnInit(): void {
        this.filteredItems.emit(this.items());
    }

    onSearch(searchTerm: Event): void {
        const filtered = this.items().filter(item =>
            item.item
                .toLowerCase()
                .includes(
                    (
                        searchTerm.target as HTMLInputElement
                    )?.value.toLowerCase(),
                ),
        );
        this.filteredItems.emit(filtered);
    }
}
